import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import {
  selectCompanyInfo,
  selectIsLoadingCompany,
} from '@selector/company.selector';
import { updateCompany } from '@action/company.action';
import Company from '@interface/company.interface';

const CompanyRegistration = ({
  showIsRegisteredToggle,
}: {
  showIsRegisteredToggle: boolean;
}) => {
  const isLoadingCompany = useAppSelector(selectIsLoadingCompany);
  const company = useAppSelector(selectCompanyInfo);
  const dispatch = useAppDispatch();

  const updateCompanyRegistrationStatus = useCallback(
    (_: any, isRegistered: boolean) => {
      dispatch(updateCompany({ isRegistered } as Company));
    },
    [dispatch],
  );

  return (
    showIsRegisteredToggle && (
      <Box
        p={2}
        mb={2}
        mt="10px"
        display="flex"
        bgcolor="primary.50"
        justifyContent="flex-end"
      >
        <FormControlLabel
          control={
            <Switch
              disabled={isLoadingCompany}
              checked={company?.isRegistered}
              onChange={updateCompanyRegistrationStatus}
              color="secondary"
            />
          }
          label={
            <Typography variant="body2" color="grey.800">
              {company?.isRegistered
                ? 'Registered Company'
                : 'Unregistered Company'}
            </Typography>
          }
        />
      </Box>
    )
  );
};

export default CompanyRegistration;
