import axios from 'axios';
import { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import { freshAxiosInstance } from '@action/auth.action';
import { ORIGIN_URL, TOKEN_KEY } from '@variable';

export const Axios = () => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const requestInterceptor = async (config: any) => {
    const token = await getAccessTokenSilently();
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers[
      'LocalAuthorization'
    ] = `Bearer ${window.sessionStorage.getItem(TOKEN_KEY)}`;
    return config;
  };

  const responseErrorInterceptor = async (err: any) => {
    const { response } = err;
    const is401 = response?.status === 401;

    if (is401) {
      await logout({
        logoutParams: { returnTo: ORIGIN_URL + '?action=logout' },
      });
    }
    if (!is401 && response?.data?.message) {
      setSnackbarMessage(response?.data?.message);
      setSnackbarOpen(true);
    }
  };

  const responseSuccessResponse = (res: any) => res;

  useEffect(() => {
    axios.interceptors.request.use(requestInterceptor, Promise.reject);
    axios.interceptors.response.use(
      responseSuccessResponse,
      responseErrorInterceptor,
    );
    freshAxiosInstance.interceptors.response.use(
      responseSuccessResponse,
      responseErrorInterceptor,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
