import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import { useAppSelector } from '@hook/hooks.hook';
import { selectCompanyWalletBalances } from '@selector/company.selector';
import AppWalletInterface from '@interface/appWallet.interface';
import USDT from '@asset/images/usdt.webp';
import USDC from '@asset/images/usdc.webp';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';

const Wallet = ({ currency, balance }: AppWalletInterface) => {
  let icon = USDT;
  let subtitle = 'Tether USD';

  if (currency === CryptoCurrency.USDC) {
    icon = USDC;
    subtitle = 'US Dollar Coin';
  }

  const value = balance.toLocaleString('en-US', {
    maximumFractionDigits: 2,
  });

  return (
    <Stack
      px={3}
      py="20px"
      direction="row"
      borderRadius={2}
      bgcolor="rgba(223, 239, 249, 0.20)"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <Box height={32} width={32}>
          <img width={'100%'} height={'auto'} src={icon} alt={currency} />
        </Box>

        <Stack>
          <Typography>{currency}</Typography>
          <Typography variant="caption" color="grey.700">
            {subtitle}
          </Typography>
        </Stack>
      </Stack>

      <Typography variant="h6" color="grey.700" fontWeight={700}>
        {value} {currency}
      </Typography>
    </Stack>
  );
};

const DEFAULT_WALLETS = [
  {
    id: 'random34545',
    currency: CryptoCurrency.USDT,
    balance: 0,
  },
  {
    id: 'random12345',
    currency: CryptoCurrency.USDC,
    balance: 0,
  },
];

const WalletBalances = () => {
  let companyWalletBalances = useAppSelector(selectCompanyWalletBalances);

  if (!companyWalletBalances?.length) companyWalletBalances = DEFAULT_WALLETS;

  return companyWalletBalances?.length ? (
    <Box
      border="1px solid"
      borderRadius="15px"
      borderColor="primary.50"
      flex={1}
      height="fit-content"
      pb={11}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        pt={3}
        pb={2}
        px={2}
      >
        <Typography variant="body1" fontSize="18px">
          Balance
        </Typography>
      </Stack>

      <Stack px={2} gap="20px">
        {companyWalletBalances?.map((values) => (
          <Wallet {...values} key={values.currency} />
        ))}
      </Stack>
    </Box>
  ) : null;
};

export default WalletBalances;
