import { createSlice } from '@reduxjs/toolkit';

import { INITIAL_STATE, SLICE_NAME } from '@variable';

import { extraReducers, reducers } from './extraReducers';

const AppSlice = createSlice({
  reducers,
  extraReducers,
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
});

export default AppSlice.reducer;
