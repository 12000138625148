import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import dayjs from 'dayjs';

import { getPayLinkById } from '@action/company.action';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { selectPayLinkById } from '@selector/company.selector';
import PayLinkStatus from '@page/PayLink/PayLinkStatus';
import { PayLinkStatusEnum } from '@interface/initialState.interface';
import TransactionDetails from '@component/TransactionDetails';

const ViewPayLink = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/dashboard/paylink');
  };

  useEffect(() => {
    dispatch(getPayLinkById(id as string)).then((res: any) => {
      if (res.error) goBack();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const payLink = useAppSelector((state) =>
    selectPayLinkById(state, id as string),
  );

  const openBlockchainTransactionDetails = () => {
    if (!payLink?.appTransaction.blockchainTransactionId) return;

    window.open(
      'https://tronscan.org/#/transaction/' +
        payLink?.appTransaction.blockchainTransactionId,
      '_blank',
    );
  };

  return (
    <Box px={2} pb={8}>
      <Button
        color="primary"
        size="small"
        variant="text"
        startIcon={<ChevronLeft />}
        onClick={goBack}
        sx={{ p: 0, mt: 3 }}
      >
        Back
      </Button>

      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Typography variant="body1" fontSize="20px" fontWeight="bold" my={4}>
          {payLink?.appName}
        </Typography>

        <Box
          sx={{
            px: 2,
            py: 4,
            p: 5,
            border: '1px solid',
            borderColor: 'primary.100',
            borderRadius: 2,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Stack gap={'12px'}>
              <Typography variant="body2" fontWeight={400}>
                Amount
              </Typography>

              <Typography variant="h4" fontWeight={700}>
                {payLink?.fiatCurrency}{' '}
                {Number(payLink?.amount).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Stack>

            <Stack gap={'12px'}>
              <Typography variant="body1" fontWeight={600}>
                Customers Email
              </Typography>

              <Typography variant="body1" fontWeight={400}>
                {payLink?.email}
              </Typography>
            </Stack>

            <Stack gap={'12px'}>
              <Typography variant="body1" fontWeight={600}>
                Date Created
              </Typography>

              <Typography variant="body1" fontWeight={400}>
                {dayjs(payLink?.createdAt).format('hh:mm:ss A, MMMM D, YYYY')}
              </Typography>
            </Stack>

            <Stack gap={'12px'}>
              <Typography variant="body1" fontWeight={600}>
                Link Status
              </Typography>

              <Typography variant="body1" fontWeight={400}>
                <PayLinkStatus status={payLink?.status as PayLinkStatusEnum} />
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>

      {payLink?.status === PayLinkStatusEnum.USED && (
        <Box
          sx={{
            maxWidth: '600px',
            width: '100%',
          }}
        >
          <Typography variant="body1" fontSize="20px" fontWeight="bold" my={4}>
            Transaction Details
          </Typography>
          <Stack
            sx={{
              p: 5,
              border: '1px solid',
              borderColor: 'primary.100',
              borderRadius: 2,
              gap: 1,
            }}
          >
            <TransactionDetails transaction={payLink?.appTransaction} />

            <Stack alignItems="flex-end">
              <Button
                color="info"
                variant="contained"
                onClick={openBlockchainTransactionDetails}
                sx={{
                  bgcolor: 'primary.50',
                  color: 'primary.900',
                  boxShadow: 0,
                  fontWeight: 500,
                }}
              >
                View on Blockchain
              </Button>
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default ViewPayLink;
