import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import { useAppSelector } from '@hook/hooks.hook';
import {
  selectCompanyDirectors,
  selectCompanyInfo,
  selectKycIsCompletelyVerified,
} from '@selector/company.selector';
import { selectBusinessOwnerKyc } from '@selector/auth.selector';
import KycCheckbox from '@page/Overview/KycCheckbox';

const KycRequirements = () => {
  const company = useAppSelector(selectCompanyInfo);
  const businessOwnerKyc = useAppSelector(selectBusinessOwnerKyc);
  const companyDirectors = useAppSelector(selectCompanyDirectors);

  const companyDirectorsVerificationDone = Boolean(
    companyDirectors?.length &&
      companyDirectors.some(
        (director) => director.verificationRequested || director.isVerified,
      ),
  );

  const businessOwnerIsVerified = Boolean(
    businessOwnerKyc?.isVerified || businessOwnerKyc?.verificationRequested,
  );

  const companyVerificationDone = Boolean(
    company.verificationRequested || company.isVerified,
  );

  const isRegistered = company.isRegistered;

  const isCompletelyVerified = useAppSelector(selectKycIsCompletelyVerified);

  const companyDirectorsVerificationRequested = Boolean(
    companyDirectors?.length &&
      companyDirectors.some((director) => director.verificationRequested),
  );
  const companyDirectorsIsVerified = Boolean(
    companyDirectors?.length &&
      companyDirectors.some((director) => director.isVerified),
  );

  let completed = 0;
  if (companyDirectorsVerificationDone && isRegistered) completed += 1;
  if (businessOwnerIsVerified) completed += 1;
  if (companyVerificationDone && isRegistered) completed += 1;

  return !isCompletelyVerified ? (
    <Box width="100%">
      <Stack direction="row" justifyContent="space-between" width="100%" mb={3}>
        <Box>
          <Typography variant="body1" fontSize="18px" mb="10px">
            KYC Requirements
          </Typography>

          <Typography color="grey.700" fontWeight={300}>
            Get started with ZinariPay by completing the required KYC.
          </Typography>
        </Box>

        <Box
          px="8px"
          py="3px"
          display="flex"
          borderRadius="5px"
          bgcolor="warning.50"
          height="fit-content"
        >
          <Typography variant="caption" color="warning.600">
            {completed}/{isRegistered ? 3 : 1} Completed
          </Typography>
        </Box>
      </Stack>

      <Stack gap={2}>
        {isRegistered && (
          <KycCheckbox
            title="Company KYC"
            to="/dashboard/settings?tab=1"
            isVerified={Boolean(company.isVerified)}
            verificationRequested={Boolean(company.verificationRequested)}
          />
        )}

        {isRegistered && (
          <KycCheckbox
            to="/dashboard/settings?tab=1"
            title="Company Director KYC"
            isVerified={companyDirectorsIsVerified}
            verificationRequested={companyDirectorsVerificationRequested}
          />
        )}

        <KycCheckbox
          to="/dashboard/settings?tab=2"
          title="Business Owner KYC"
          isVerified={Boolean(businessOwnerKyc?.isVerified)}
          verificationRequested={Boolean(
            businessOwnerKyc?.verificationRequested,
          )}
        />
      </Stack>
    </Box>
  ) : null;
};

export default KycRequirements;
