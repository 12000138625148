import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { Field, useFormikContext } from 'formik';

import { STATES_MAP } from '@variable';
import CountrySelectField from '@component/CountrySelectField';
import { CompanyDirector } from '@interface/company.interface';

import FileUploadField from '../FileUploadField';

const AddressInformationStep = ({ isReadonly }: { isReadonly: boolean }) => {
  const { touched, errors, values } = useFormikContext<CompanyDirector>();

  return (
    <>
      <CountrySelectField
        name="countryOfResidence"
        label="Country of Residence"
        fullWidth
        variant="outlined"
        error={touched.countryOfResidence && !!errors.countryOfResidence}
        helperText={touched.countryOfResidence && errors.countryOfResidence}
      />

      {values.countryOfResidence && STATES_MAP[values.countryOfResidence] && (
        <Field
          disabled={isReadonly}
          as={TextField}
          name="state"
          label="Region"
          select
          fullWidth
          margin="normal"
          variant="outlined"
          helperText={touched.state && errors.state}
          error={touched.state && Boolean(errors.state)}
        >
          {STATES_MAP[values.countryOfResidence].map(
            ({ value, title }: any) => (
              <MenuItem key={value} value={value}>
                {title}
              </MenuItem>
            ),
          )}
        </Field>
      )}
      <Field
        disabled={isReadonly}
        as={TextField}
        name="city"
        label="City"
        fullWidth
        margin="normal"
        variant="outlined"
        helperText={touched.city && errors.city}
        error={touched.city && Boolean(errors.city)}
      />
      <Field
        disabled={isReadonly}
        as={TextField}
        name="street"
        label="Street"
        fullWidth
        margin="normal"
        variant="outlined"
        helperText={touched.street && errors.street}
        error={touched.street && Boolean(errors.street)}
      />
      <FileUploadField
        readOnly={isReadonly}
        uwConfig={{
          folder: 'utility_bills',
          tags: ['apps', 'utility_bills', 'verification'],
        }}
        name="utilityBillUpload"
        label="Upload Proof of address (Utility bill)"
      />
    </>
  );
};

export default AddressInformationStep;
