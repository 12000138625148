import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_URL, SLICE_NAME } from '@variable';
import AuthResponse from '@interface/authResponse.interface';

export const freshAxiosInstance = axios.create({ withCredentials: true });

export const login = createAsyncThunk(
  `${SLICE_NAME}/login`,
  async (payload: {
    email: string;
    password: string;
  }): Promise<AuthResponse> => {
    const result = await freshAxiosInstance.post(
      `${API_URL}/auth/login`,
      payload,
    );
    return result.data;
  },
);

export const updatePassword = createAsyncThunk(
  `${SLICE_NAME}/updatePassword`,
  async (payload: {
    oldPassword: string;
    password: string;
  }): Promise<{ message: string }> => {
    const result = await axios.put(`${API_URL}/auth/update-password`, payload);
    return result.data;
  },
);

export const register = createAsyncThunk(
  `${SLICE_NAME}/register`,
  async (payload: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    companyName: string;
  }): Promise<AuthResponse> => {
    const result = await freshAxiosInstance.post(
      `${API_URL}/auth/register`,
      payload,
    );
    return result.data;
  },
);

export const authenticate = createAsyncThunk(
  `${SLICE_NAME}/authenticate`,
  async (data: any): Promise<AuthResponse> => {
    const result = await axios.post(`${API_URL}/auth/authenticate`, data);
    return result.data;
  },
);

export const refreshToken = createAsyncThunk(
  `${SLICE_NAME}/refreshToken`,
  async (): Promise<AuthResponse> => {
    const response = await freshAxiosInstance(`${API_URL}/auth/refresh-token`);
    return response.data;
  },
);

export const logout = createAction(`${SLICE_NAME}/logout`);
