import React, { ReactNode, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import LoadingButton from '@component/LoadingButton';

interface ConfirmationDialogProps {
  title: string | ReactNode;
  color?: string;
  subtitle: string | ReactNode;
  onConfirm: () => any | Promise<any>;
  open: boolean;
  onClose: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  subtitle,
  onConfirm,
  open,
  onClose,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  color = 'primary',
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await onConfirm();
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle
        id="confirmation-dialog-title"
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontFamily: 'Lato',
          mt: 7,
        }}
      >
        {title}
      </DialogTitle>

      <DialogContent sx={{ minWidth: '409px' }}>
        <DialogContentText
          id="confirmation-dialog-description"
          style={{ textAlign: 'center' }}
        >
          {subtitle}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'center', py: 2, pb: 7 }}>
        <LoadingButton
          onClick={handleConfirm}
          variant="contained"
          color={color as any}
          size="large"
          isLoading={loading}
        >
          {confirmButtonText}
        </LoadingButton>
        <Button
          onClick={onClose}
          size="large"
          variant="outlined"
          color={color as any}
          disabled={loading}
        >
          {cancelButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
