import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { selectCompanyInfo } from '@selector/company.selector';
import { selectCanEditCompanyDetails } from '@selector/permission.selector';
import { selectBusinessOwnerKyc } from '@selector/auth.selector';
import { getCompanyDetails, setPageTitle } from '@action/company.action';
import WalletBalances from '@page/Overview/WalletBalances';

import HeroSection from './HeroSection';
import CompanyRegistration from './CompanyRegistration';
import KycRequirements from './KycRequirements';
import RecentTransactions from './RecentTransactions';

const Overview = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompanyInfo);
  const canEditCompanyDetails = useAppSelector(selectCanEditCompanyDetails);
  useState(false);
  const businessOwnerKyc = useAppSelector(selectBusinessOwnerKyc);

  useEffect(() => {
    dispatch(getCompanyDetails());
    dispatch(setPageTitle('Overview'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const businessOwnerKycIsVerified =
    !company?.isRegistered && businessOwnerKyc.isVerified;

  const showIsRegisteredToggle =
    !businessOwnerKycIsVerified &&
    !company.isVerified &&
    !company.verificationRequested &&
    canEditCompanyDetails;

  let title = 'Welcome,';
  if (businessOwnerKyc.firstName)
    title = title.replace(',', ` ${businessOwnerKyc.firstName}`);

  return (
    <Box pb={4}>
      <CompanyRegistration showIsRegisteredToggle={showIsRegisteredToggle} />

      <HeroSection
        showIsRegisteredToggle={showIsRegisteredToggle}
        title={title}
      />

      <Stack px={3} pt={8} width="100%" direction="row" gap={3}>
        <Stack maxWidth="630px" width="100%" gap={3}>
          <KycRequirements />

          <RecentTransactions />
        </Stack>

        <WalletBalances />
      </Stack>
    </Box>
  );
};

export default Overview;
