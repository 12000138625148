import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import { ALLOWED_NATIONALITIES_MAP } from '@variable';
import { CompanyDirector } from '@interface/company.interface';

const PersonalInformationStep = ({ isReadonly }: { isReadonly: boolean }) => {
  const { touched, errors } = useFormikContext<CompanyDirector>();

  return (
    <>
      <Field
        disabled={isReadonly}
        as={TextField}
        name="fullName"
        label="Full Name"
        fullWidth
        margin="normal"
        variant="outlined"
        helperText={touched.fullName && errors.fullName}
        error={touched.fullName && Boolean(errors.fullName)}
      />
      <Field
        component={({ field, form }: any) => (
          <DatePicker
            label="Date of Birth"
            value={dayjs(field.value)}
            onChange={(value) => form.setFieldValue(field.name, value)}
          />
        )}
        name="dateOfBirth"
        label="Date of Birth"
        type="date"
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        helperText={touched.dateOfBirth && errors.dateOfBirth}
        error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
      />
      <Field
        disabled={isReadonly}
        as={TextField}
        name="nationality"
        label="Nationality"
        select
        fullWidth
        margin="normal"
        variant="outlined"
        helperText={touched.nationality && errors.nationality}
        error={touched.nationality && Boolean(errors.nationality)}
      >
        {ALLOWED_NATIONALITIES_MAP.map(({ value, title }) => (
          <MenuItem key={value} value={value}>
            {title}
          </MenuItem>
        ))}
      </Field>
    </>
  );
};

export default PersonalInformationStep;
