enum CompanyType {
  LLC = 'llc',
  SOLE_PROPRIETOR = 'sole-proprietor',
  PARTNERSHIP = 'partnership',
  CORPORATION = 'corporation',
  S_CORP = 's-corp',
  NONPROFIT = 'nonprofit',
  COOPERATIVE = 'cooperative',
  TRUST = 'trust',
}

export default CompanyType;
