import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import CompanyType from '@enum/companyType.enum';
import Company from '@interface/company.interface';
import { useAppDispatch } from '@hook/hooks.hook';
import { updateCompany } from '@action/company.action';
import { PHONE_NUMBER_REGEX } from '@variable';
import CompanyForm from '@component/CompanyInfoForm/CompanyForm';

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, 'Name must be at least 2 characters')
    .required('Name is required'),
  type: Yup.string().required('Company type is required'),
  countryOfIncorporation: Yup.string().required(
    'Country of incorporation is required',
  ),
  cacDocUpload: Yup.string().required('Document is required'),
  supportEmail: Yup.string()
    .required('Support Email is required')
    .email('Invalid email address'),
  supportPhoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(PHONE_NUMBER_REGEX, 'Phone Number format is invalid'),
});

const CompanyInfoForm: React.FC<Company> = ({
  name,
  type,
  countryOfIncorporation,
  supportEmail,
  supportPhoneNumber,
  cacDocUpload,
}) => {
  const initialValues = {
    name: name || '',
    type: type || ('' as CompanyType),
    countryOfIncorporation: countryOfIncorporation || 'NG',
    supportEmail: supportEmail || '',
    cacDocUpload: cacDocUpload || '',
    supportPhoneNumber: supportPhoneNumber || '',
  } as Company;
  const dispatch = useAppDispatch();

  const handleSubmit = (values: Company) => {
    dispatch(updateCompany(values));
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <CompanyForm
            name={values.name}
            type={values.type}
            countryOfIncorporation={values.countryOfIncorporation}
            supportEmail={values.supportEmail}
            cacDocUpload={values.cacDocUpload}
            supportPhoneNumber={values.supportPhoneNumber}
            initialValues={initialValues}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CompanyInfoForm;
