import { useParams } from 'react-router-dom';

import TeamMemberDetail from '@component/TeamMemberDetail/TeamMemberDetail';
import { selectTeamMemberById } from '@selector/company.selector';
import { useAppSelector } from '@hook/hooks.hook';

const ManageTeamMembers = () => {
  const { id } = useParams();

  const teamMember = useAppSelector((state) => {
    return selectTeamMemberById(state, { id });
  });

  return (
    <TeamMemberDetail
      member={teamMember}
      isEditing={id?.toLowerCase() !== 'new'}
    />
  );
};

export default ManageTeamMembers;
