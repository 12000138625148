import React, { FC, useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import isEqual from 'lodash.isequal';
import CloseIcon from '@mui/icons-material/Close';

import { DirectorFormProps } from '@interface/company.interface';
import CompanyDirectorDetailView from '@component/CompanyDirectorDetailView';
import {
  addCompanyDirector,
  updateCompanyDirector,
} from '@action/company.action';
import { useAppDispatch } from '@hook/hooks.hook';
import LoadingButton from '@component/LoadingButton';

import PersonalInformationStep from './steps/PersonalInformationStep';
import IdentificationStep from './steps/IdentificationStep';
import ContactInformationStep from './steps/ContactInformationStep';
import AddressInformationStep from './steps/AddressInformationStep';
import { getInitialValues, validationSchema } from './validation';

const steps = [
  'Personal Information',
  'Identification',
  'Address Information',
  'Contact Information',
];

const DirectorDrawerForm: FC<DirectorFormProps> = ({
  open,
  onClose,
  director: initialDirector,
}) => {
  const [director, setDirector] = useState(initialDirector);
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const initialValues = getInitialValues(director);

  const handleNext = useCallback(
    async (values: any) => {
      setIsSubmitting(true);
      const action = director?.id ? updateCompanyDirector : addCompanyDirector;
      const noChange = isEqual(values, initialValues);

      const res = noChange ? {} : ((await dispatch(action(values))) as any);

      if (!res.error) {
        if (activeStep !== steps.length - 1) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          if (res.payload)
            setDirector((oldDirector) => ({
              ...oldDirector,
              ...res.payload,
            }));
        } else {
          onClose();
        }
      }

      setIsSubmitting(false);
    },
    [activeStep, director, dispatch, initialValues, onClose],
  );

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step: number) => {
    const props = { isReadonly: Boolean(director?.isVerified) };

    switch (step) {
      case 0:
        return <PersonalInformationStep {...props} />;
      case 1:
        return <IdentificationStep {...props} />;
      case 2:
        return <AddressInformationStep {...props} />;
      case 3:
        return <ContactInformationStep {...props} />;
      default:
        return null;
    }
  };

  const title = (() => {
    if (director?.isVerified || director?.verificationRequested)
      return 'Viewing Director';
    return director ? 'Editing Director' : 'Add Director';
  })();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle fontSize={'20px'} fontWeight={600} fontFamily="Lato">
        {title}
      </DialogTitle>

      <IconButton
        onClick={onClose}
        size="small"
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: 'grey.600',
        }}
      >
        <CloseIcon />
      </IconButton>

      <Divider />

      <DialogContent>
        {director?.isVerified && (
          <Box my={1}>
            <Alert color="info">
              You&apos;ll need to contact support to update/edit this director.
            </Alert>
          </Box>
        )}

        {!director?.isVerified && director?.verificationRequested && (
          <Box my={1}>
            <Alert
              severity={director?.verificationRequested ? 'warning' : 'info'}
            >
              You can not edit this director because their profile has been
              submitted for verification.
            </Alert>
          </Box>
        )}

        <Box>
          {!director?.isVerified && !director?.verificationRequested ? (
            <Formik
              enableReinitialize
              onSubmit={handleNext}
              initialValues={initialValues}
              validationSchema={validationSchema[activeStep]}
            >
              {(formikProps) => (
                <Form>
                  <Stack direction="row" gap={3}>
                    <Box>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Stack width="296px" gap={3}>
                          {renderStepContent(activeStep)}
                        </Stack>
                      </Box>

                      <Stack
                        gap={2}
                        direction="row"
                        my={4}
                        justifyContent="flex-end"
                      >
                        {activeStep !== 0 && (
                          <Button onClick={handleBack} variant="outlined">
                            Back
                          </Button>
                        )}

                        <LoadingButton
                          type="submit"
                          disabled={!formikProps.isValid}
                          color={'primary'}
                          variant="contained"
                          isLoading={isSubmitting}
                        >
                          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </LoadingButton>
                      </Stack>
                    </Box>

                    {!director?.isVerified &&
                      !director?.verificationRequested && (
                        <Stack
                          gap={2}
                          sx={{
                            '&:hover': { cursor: 'pointer' },
                            width: '200px',
                          }}
                        >
                          {steps.map((label, index) => (
                            <Stack
                              direction={'row'}
                              alignItems="center"
                              gap={1}
                              key={label}
                              onClick={() => {
                                if (director) setActiveStep(index);
                              }}
                            >
                              <Stack
                                textAlign="center"
                                alignItems="center"
                                justifyContent="center"
                                height={'18px'}
                                width={'18px'}
                                borderRadius={'50%'}
                                bgcolor={
                                  activeStep === index
                                    ? 'secondary.700'
                                    : 'grey.100'
                                }
                                color={
                                  activeStep === index ? 'white' : 'grey.500'
                                }
                              >
                                <Typography variant="caption">
                                  {index + 1}
                                </Typography>
                              </Stack>

                              <Typography
                                variant="body1"
                                color={
                                  activeStep === index ? 'secondary.700' : ''
                                }
                              >
                                {label}
                              </Typography>
                            </Stack>
                          ))}
                        </Stack>
                      )}
                  </Stack>
                </Form>
              )}
            </Formik>
          ) : (
            <CompanyDirectorDetailView {...director} />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DirectorDrawerForm;
