import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { getCompanyPayLinks } from '@action/company.action';
import { useAppDispatch } from '@hook/hooks.hook';
import { selectPayLinks } from '@selector/company.selector';
import PayLinkStatus from '@page/PayLink/PayLinkStatus';

const HEADER = ['App', 'Email', 'Amount', 'Date Created', 'Status'];

const PayLinkTable = ({
  searchTerm,
  setSearchTerm,
  onOpenPayLinkDialog,
  searchPerformed,
  setSearchPerformed,
}: {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  searchPerformed: boolean;
  setSearchPerformed: (value: boolean) => void;
  onOpenPayLinkDialog: () => void;
}) => {
  const payLinks = useSelector(selectPayLinks);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSearch = useCallback(
    async (values?: any) => {
      const { page: newPage, rows: newRowsPerPage } = values || {};
      await dispatch(
        getCompanyPayLinks({
          page: newPage || page || 1,
          limit: newRowsPerPage || rowsPerPage,
          searchQuery: searchTerm,
        }),
      );
      setSearchPerformed(true);
    },
    [dispatch, page, rowsPerPage, searchTerm, setSearchPerformed],
  );

  useEffect(() => {
    setSearchPerformed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = async (_: unknown, newPage: number) => {
    setPage(newPage);
    await handleSearch({ page: newPage + 1 });
  };

  const handleRowsPerPageChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(newRowsPerPage);
      setPage(0);
      await handleSearch({ rows: newRowsPerPage, page: 1 });
    },
    [handleSearch],
  );

  const setPayLinkId = (id: string) => {
    navigate(`/dashboard/paylink/${id}`);
  };

  return (
    <Box mb={10}>
      <Toolbar sx={{ justifyContent: 'space-between', my: 3 }}>
        <TextField
          variant="outlined"
          placeholder="Search by email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setSearchPerformed(false);
          }}
          onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          sx={{ width: '100%', maxWidth: '500px' }}
        />

        <Button
          color="primary"
          variant="contained"
          onClick={onOpenPayLinkDialog}
        >
          Create PayLink
        </Button>
      </Toolbar>

      {!searchPerformed && (
        <Box p={3}>
          <Typography variant="body1" color="text.secondary">
            Press <b>ENTER</b>
          </Typography>
        </Box>
      )}

      {!payLinks?.data?.length && searchPerformed && (
        <Box p={3}>
          <Typography variant="body1" align="center" color="text.secondary">
            No results {searchTerm && <>for &quot;{searchTerm}&quot;</>}
          </Typography>
        </Box>
      )}

      {searchPerformed &&
        Boolean(searchTerm) &&
        Boolean(payLinks?.data?.length) && (
          <Box p={2}>
            <Typography>
              Search result(s) for &quot;<b>{searchTerm}</b>&quot;
            </Typography>
          </Box>
        )}

      {Boolean(payLinks?.data?.length) && searchPerformed && (
        <TableContainer sx={{ px: 3 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: 'rgba(223, 239, 249, 0.20)' }}>
                {HEADER.map((header) => (
                  <TableCell key={header} sx={{ border: 0 }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {payLinks?.data.map(
                ({
                  appName,
                  status,
                  id,
                  fiatCurrency,
                  amount,
                  email,
                  createdAt,
                }) => (
                  <TableRow
                    key={id}
                    onClick={() => setPayLinkId(id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell sx={{ borderColor: 'grey.200' }}>
                      {appName}
                    </TableCell>
                    <TableCell sx={{ borderColor: 'grey.200' }}>
                      {email}
                    </TableCell>

                    <TableCell sx={{ borderColor: 'grey.200' }}>
                      {fiatCurrency}{' '}
                      {Number(amount).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>

                    <TableCell>
                      {dayjs(createdAt).format('hh:mm A MMMM D, YYYY')}
                    </TableCell>

                    <TableCell sx={{ borderColor: 'grey.200' }}>
                      <PayLinkStatus status={status} />
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={payLinks?.totalRecords || 0}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPageOptions={[30, 60, 100]}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            slotProps={{
              actions: {
                previousButton: { 'aria-label': 'Previous Page' },
                nextButton: { 'aria-label': 'Next Page' },
              },
            }}
          />
        </TableContainer>
      )}
    </Box>
  );
};

export default PayLinkTable;
