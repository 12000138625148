import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const ActionCard = ({
  to,
  icon,
  title,
  blank,
  description,
}: {
  to: string;
  icon: any;
  blank?: boolean;
  title: string;
  description: string;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (blank) window.open(to, '_blank');
    else navigate(to);
  }, [blank, navigate, to]);

  return (
    <Stack
      px={3}
      gap={2}
      maxWidth="235px"
      borderLeft="1px solid"
      alignItems="flex-start"
      borderColor="primary.50"
    >
      <Box color={'primary.200'} height={32} width={32}>
        {icon}
      </Box>

      <Typography variant="body2" color="primary.900">
        <b>{title} </b>
        <Typography component="span" variant="body2" color="grey.700">
          {description}
        </Typography>
      </Typography>

      <Button
        variant="text"
        color="secondary"
        onClick={handleClick}
        sx={{ justifySelf: 'flex-end', p: 0 }}
      >
        Get Started
      </Button>
    </Stack>
  );
};

export default ActionCard;
