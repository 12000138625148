import { Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';

import unsupportedDevice from '@asset/images/unsupportedDevice.webp';

const UseDesktop = () => {
  return (
    <Stack
      height="100vh"
      width="100vw"
      alignItems="center"
      position="relative"
      justifyContent="center"
      textAlign="center"
    >
      <Box position="absolute" bottom={10} p={1}>
        <Typography>
          Need help or have questions? Contact us at{' '}
          <Link href="mailto:support@zinari.io" color="secondary.600">
            support@zinari.io
          </Link>
          .
        </Typography>
      </Box>
      <img
        src="/logoDefault.webp"
        alt="zinaripay logo"
        width={140}
        height="auto"
        style={{ position: 'absolute', top: 30, left: 30 }}
      />
      <img
        src={unsupportedDevice}
        alt="unsupported device"
        width="100%"
        style={{
          maxWidth: 500,
        }}
        height="auto"
      />
      <Stack maxWidth="300px" gap={2}>
        <Typography variant="body1" fontSize="18px" fontWeight={600}>
          Your Best Experience Awaits on a Bigger Screen!
        </Typography>

        <Typography color="grey.700" variant="body2">
          Our console is optimized for desktops and iPads to provide the best
          user experience. Please switch to one of these devices to access all
          features seamlessly.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default UseDesktop;
