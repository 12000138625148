import { Alert, Typography } from '@mui/material';

import { selectUserRole } from '@selector/permission.selector';
import { useAppSelector } from '@hook/hooks.hook';
import { IS_DEV, ROLE_MAP } from '@variable';

const DisplayEnvironmentRole = () => {
  const userRole = useAppSelector(selectUserRole);

  return IS_DEV && userRole ? (
    <Alert
      severity="info"
      sx={{
        position: 'fixed',
        bottom: 10,
        right: 10,
      }}
    >
      <Typography variant="caption">{ROLE_MAP[userRole]}</Typography>
    </Alert>
  ) : null;
};

export default DisplayEnvironmentRole;
