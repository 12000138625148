import { createSelector } from '@reduxjs/toolkit';

import { SLICE_NAME } from '@variable';
import { RootState } from '@store';

export const selectApps = (state: RootState) => state[SLICE_NAME].apps;

export const selectApp = createSelector(
  [selectApps, (_, id: string) => id],
  (apps, id) => {
    return apps.find((app) => app.id === id);
  },
);

export const selectTransactionModalId = (state: RootState) =>
  state[SLICE_NAME].transactionModalId;

export const selectIsWithdrawing = (state: RootState) =>
  state[SLICE_NAME].isWithdrawing;

export const selectIsLoadingApps = (state: RootState) =>
  state[SLICE_NAME].isLoadingApps;

export const selectIsUpdatingApp = (state: RootState) =>
  state[SLICE_NAME].isUpdatingApp;

export const selectAppTransactions = (state: RootState) =>
  state[SLICE_NAME].appTransactions;

export const selectAppTransactionsByAppIdAndCurrency = createSelector(
  [selectAppTransactions, (_, props) => props],
  (appTransactions, { id, currency }) => appTransactions[id]?.[currency],
);

export const selectAppTransactionLoading = createSelector(
  (state: RootState) => state[SLICE_NAME].isLoadingAppTransactions,
  (_, props) => props,
  (isLoadingAppTransactions, { id, currency }) =>
    isLoadingAppTransactions[id]?.[currency] || false,
);
