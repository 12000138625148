import React, { useState } from 'react';
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import { getCountryCallingCode } from 'libphonenumber-js';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteIcon from '@mui/icons-material/DeleteForeverRounded';

import { DirectorCardProps } from '@interface/company.interface';
import ConfirmationDialog from '@component/ConfirmationDialog';
import {
  deleteCompanyDirector,
  requestCompanyDirectorVerification,
} from '@action/company.action';
import { useAppDispatch } from '@hook/hooks.hook';

const FIELDS = [
  'fullName',
  'dateOfBirth',
  'nationality',
  'phoneNumber',
  'countryOfResidence',
  'email',
  'idUpload',
  'state',
  'street',
  'city',
  'utilityBillUpload',
];

const DirectorCard: React.FC<DirectorCardProps> = ({ onEdit, director }) => {
  const dispatch = useAppDispatch();
  const [submissionDialogOpen, setSubmissionDialogOpen] = useState(false);
  const [deletionDialogOpen, setDeletionDialogOpen] = useState(false);

  const incompleteInfo = ((): boolean =>
    FIELDS.some((field) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return !director[field];
    }))();

  const canBeSubmitted =
    !incompleteInfo && !director.isVerified && !director.verificationRequested;

  const handleSubmitForVerification = () => {
    dispatch(requestCompanyDirectorVerification(director?.id));
  };

  const handleDelete = () => {
    dispatch(deleteCompanyDirector(director?.id));
  };

  const onOpenSubmissionDialog = () => {
    setSubmissionDialogOpen(true);
  };

  const onOpenDeletionDialogOpen = () => {
    setDeletionDialogOpen(true);
  };

  const confirmationDialogProps = {
    open: submissionDialogOpen || deletionDialogOpen,
    title: submissionDialogOpen ? 'Submitting Director' : 'Deleting Director',
    subtitle: submissionDialogOpen ? (
      <>
        <div>
          Are you sure you want to submit <b>{director?.fullName}&apos;s</b>{' '}
          details for verification?
        </div>
        <div>You can not undo this action once it&apos;s done</div>
      </>
    ) : (
      <>
        <div>
          Are you sure you want to delete <b>{director?.fullName}&apos;s</b>?
        </div>
        <div>You can not undo this action once it&apos;s done</div>
      </>
    ),
    onClose: () => {
      const close = submissionDialogOpen
        ? setSubmissionDialogOpen
        : setDeletionDialogOpen;
      close(false);
    },
    onConfirm: submissionDialogOpen
      ? handleSubmitForVerification
      : handleDelete,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
  };

  return (
    <>
      <Card
        variant="outlined"
        style={{ marginBottom: 16, height: '100%' }}
        sx={{
          borderColor: 'primary.100',
          borderRadius: '10px',
        }}
      >
        <CardContent sx={{ position: 'relative', height: '100%' }}>
          <Stack gap={1} pb={4}>
            <Typography variant="body1" fontSize={'18px'}>
              {director.fullName}
            </Typography>

            {!incompleteInfo && Boolean(director.email) && (
              <Typography variant="body1" color="grey.800">
                Email: {director.email}{' '}
              </Typography>
            )}

            {!incompleteInfo && director.phoneNumber && (
              <Typography variant="body1" color="grey.800">
                Phone:{' '}
                {director.countryOfResidence
                  ? `+${getCountryCallingCode(
                      director?.countryOfResidence as any,
                    )}`
                  : ''}{' '}
                {director.phoneNumber}
              </Typography>
            )}
          </Stack>

          <Stack
            direction="row"
            justifyContent="flex-end"
            gap={2}
            sx={{
              position: 'absolute',
              bottom: 16,
              right: 16,
            }}
          >
            <Tooltip
              title={
                !director?.isVerified && !director.verificationRequested
                  ? 'Edit'
                  : 'View'
              }
            >
              <IconButton onClick={() => onEdit(director)} size="small">
                {!director?.isVerified && !director.verificationRequested ? (
                  <EditIcon color="primary" />
                ) : (
                  <VisibilityRoundedIcon color="primary" />
                )}
              </IconButton>
            </Tooltip>

            {!director?.isVerified && !director.verificationRequested && (
              <Tooltip title="Delete">
                <IconButton
                  onClick={onOpenDeletionDialogOpen}
                  size="small"
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}

            {canBeSubmitted && (
              <Tooltip title="Submit for verification">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={onOpenSubmissionDialog}
                >
                  <SendIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </CardContent>
      </Card>

      <ConfirmationDialog {...confirmationDialogProps} />
    </>
  );
};

export default DirectorCard;
