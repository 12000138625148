import { Navigate, useLocation } from 'react-router-dom';
import React, { FC, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useAuth } from '@hook/useAuth.hook';
import { useAppSelector } from '@hook/hooks.hook';
import { selectUser } from '@selector/auth.selector';
import AppLoader from '@component/AppLoader';
import NotVerified from '@component/NotVerified';
import PendingTerms from '@component/PendingTerms';

const ProtectedRoute: FC<{ children: any }> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const user = useAppSelector(selectUser);
  const { setRedirectPath } = useAuth();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (!isAuthenticated) setRedirectPath(pathname + search || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (!isAuthenticated) return <Navigate to="/login" />;
  if (isLoading || !user) return <AppLoader />;
  if (!user?.isVerified) return <NotVerified email={user?.email} />;
  return !user?.agreedToTerms ? <PendingTerms /> : children;
};

export default ProtectedRoute;
