import { Button } from '@mui/material';
import React, { useState } from 'react';

import NoActivities from '@component/NoActivities';
import CreateAppDialog from '@component/CreateAppDialog';

const NoApps = () => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const toggleOpenCreateDialog = () => {
    setOpenCreateDialog((value) => !value);
  };

  return (
    <>
      <NoActivities
        title="No Apps Yet"
        subTitle={
          <>
            You don&apos;t have any apps yet. <br />
            Once you create one, it will appear here.
          </>
        }
        py={10}
        minHeight="85vh"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={toggleOpenCreateDialog}
        >
          Add new app
        </Button>
      </NoActivities>

      <CreateAppDialog
        open={openCreateDialog}
        onClose={toggleOpenCreateDialog}
      />
    </>
  );
};

export default NoApps;
