import React from 'react';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { AdvancedImage } from '@cloudinary/react';
import { ChevronRight } from '@mui/icons-material';

import cld from '@localCloudinary';
import { AppMode } from '@enum/appMode.enum';
import App from '@interface/app.interface';

const AppCard: React.FC<App> = ({ id, name, logoUrl, mode, fiatCurrency }) => {
  const navigate = useNavigate();
  const theme: any = useTheme();
  const handleCardClick = () => {
    navigate(`${id}`);
  };
  const inProduction = mode === AppMode.PRODUCTION;

  const img = cld
    .image(logoUrl)
    .format('auto')
    .quality('auto')
    .resize(auto().gravity(autoGravity()).width(100).height(100));

  return (
    <Stack
      direction="row"
      width="100%"
      borderTop="1px solid"
      borderColor="primary.50"
      justifyContent="space-between"
      onClick={handleCardClick}
      p={'20px'}
      sx={{
        cursor: 'pointer',
        '&:hover': { bgcolor: 'rgba(223, 239, 249, 0.20)' },
      }}
    >
      <Stack width="100%" direction="row" alignItems="center" gap={5}>
        <AdvancedImage cldImg={img} style={{ borderRadius: 10 }} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <Box sx={{ flex: '1 0 auto' }}>
            <Typography
              variant="body1"
              fontWeight={600}
              fontSize="20px"
              mb={'12px'}
            >
              {name} ({fiatCurrency})
            </Typography>

            <Stack direction="row" gap={1} alignItems="center">
              <Typography variant="body1" color="grey.600">
                {inProduction ? 'Production' : 'Development'}
              </Typography>
              <Box
                height={10}
                width={10}
                borderRadius={2}
                bgcolor={theme.palette[inProduction ? 'success' : 'error'].main}
              />
            </Stack>
          </Box>
        </Box>
      </Stack>

      <Button
        variant="text"
        color="secondary"
        sx={{ justifySelf: 'flex-end', p: 0 }}
        endIcon={<ChevronRight color="inherit" />}
      >
        View
      </Button>
    </Stack>
  );
};

export default AppCard;
