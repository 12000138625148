import {
  ActionReducerMapBuilder,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';

import InitialState from '@interface/initialState.interface';
import {
  authenticate,
  login,
  logout,
  refreshToken,
  register,
  updatePassword,
} from '@action/auth.action';
import { INITIAL_STATE, TOKEN_KEY } from '@variable';
import { agreeTerms, fetchUserData, updateUser } from '@action/user.action';
import {
  createApp,
  createPaymentLink,
  deleteApp,
  fetchApps,
  fetchAppTransactions,
  generateNewApiKey,
  generateNewPublicKey,
  toggleAppMode,
  updateApp,
  withdrawCrypto,
} from '@action/app.action';
import {
  addCompanyDirector,
  addOrUpdateBusinessOwnerKyc,
  addTeamMember,
  deleteCompanyDirector,
  getCompanyDetails,
  getCompanyPayLinks,
  getCompanyTeamMembers,
  getCompanyTransactions,
  getPayLinkById,
  rehireTeamMember,
  requestBusinessOwnerVerification,
  requestCompanyDirectorVerification,
  requestCompanyVerification,
  setPageTitle,
  setTransactionModalId,
  terminateTeamMember,
  updateCompany,
  updateCompanyDirector,
  updateTeamMember,
} from '@action/company.action';
import { CompanyDirector } from '@interface/company.interface';
import BusinessOwnerKyc from '@interface/businessOwnerKyc.interface';
import App from '@interface/app.interface';

export const extraReducers = (
  builder: ActionReducerMapBuilder<InitialState>,
) => {
  builder.addMatcher(
    isFulfilled(
      login,
      register,
      refreshToken,
      authenticate,
      fetchUserData,
      updateUser,
      agreeTerms,
    ),
    (state: InitialState, { payload }) => {
      const { token, company, ...user } = payload || ({} as any);

      const newState = {
        ...state,
        isAuthenticating: false,
        isUpdatingUser: false,
        isAuthenticated: true,
      };

      if (token) window.sessionStorage.setItem(TOKEN_KEY, token);
      if (Object.values(company || {}).length) {
        newState.company = { ...newState.company, ...company };
      }
      if (Object.values(company.businessOwnerKyc || {}).length) {
        newState.businessOwnerKyc = {
          ...(newState?.businessOwnerKyc || {}),
          ...company?.businessOwnerKyc,
        } as BusinessOwnerKyc;
      }
      if (Object.values(user || {}).length) {
        newState.user = { ...newState.user, ...user };
      }

      return newState;
    },
  );

  builder.addMatcher(
    isPending(updateUser, agreeTerms),
    (state: InitialState) => {
      return { ...state, isUpdatingUser: true };
    },
  );

  builder.addMatcher(
    isRejected(updateUser, agreeTerms),
    (state: InitialState) => {
      return { ...state, isUpdatingUser: false };
    },
  );

  builder.addMatcher(
    isPending(addOrUpdateBusinessOwnerKyc, requestBusinessOwnerVerification),
    (state: InitialState) => {
      return { ...state, isAddingOrUpdatingBusinessOwnerKyc: true };
    },
  );

  builder.addMatcher(
    isPending(addOrUpdateBusinessOwnerKyc, requestBusinessOwnerVerification),
    (state: InitialState) => {
      return { ...state, isAddingOrUpdatingBusinessOwnerKyc: false };
    },
  );

  builder.addMatcher(
    isFulfilled(addOrUpdateBusinessOwnerKyc, requestBusinessOwnerVerification),
    (state: InitialState, { payload: businessOwnerKyc }) => {
      return {
        ...state,
        isAddingOrUpdatingBusinessOwnerKyc: false,
        businessOwnerKyc: { ...state.businessOwnerKyc, ...businessOwnerKyc },
      };
    },
  );

  builder.addMatcher(fetchApps.pending.match, (state: InitialState) => {
    return { ...state, isLoadingApps: true };
  });

  builder.addMatcher(
    fetchAppTransactions.pending.match,
    (
      state: InitialState,
      {
        meta: {
          arg: { appId, currency },
        },
      },
    ) => {
      return {
        ...state,
        isLoadingAppTransactions: {
          ...state.isLoadingAppTransactions,
          [appId]: {
            ...state.isLoadingAppTransactions[appId],
            [currency]: true,
          },
        },
      };
    },
  );

  builder.addMatcher(
    fetchAppTransactions.rejected.match,
    (
      state: InitialState,
      {
        meta: {
          arg: { appId, currency },
        },
      },
    ) => {
      return {
        ...state,
        isLoadingAppTransactions: {
          ...state.isLoadingAppTransactions,
          [appId]: {
            ...state.isLoadingAppTransactions[appId],
            [currency]: false,
          },
        },
      };
    },
  );

  builder.addMatcher(
    fetchAppTransactions.fulfilled.match,
    (
      state: InitialState,
      {
        meta: {
          arg: { appId, currency },
        },
        payload,
      },
    ) => {
      return {
        ...state,
        appTransactions: {
          ...state.appTransactions,
          [appId]: {
            [currency]: {
              ...payload,
              data:
                payload.currentPage === 1
                  ? payload.data
                  : [
                      ...(state.appTransactions[appId]?.[currency]?.data || []),
                      ...payload.data,
                    ],
            },
          },
        },
        isLoadingAppTransactions: {
          ...state.isLoadingAppTransactions,
          [appId]: {
            ...state.isLoadingAppTransactions[appId],
            [currency]: false,
          },
        },
      };
    },
  );

  builder.addMatcher(
    isPending(
      updateApp,
      toggleAppMode,
      deleteApp,
      generateNewPublicKey,
      generateNewApiKey,
    ),
    (state) => {
      return { ...state, isUpdatingApp: true };
    },
  );

  builder.addMatcher(
    isRejected(
      updateApp,
      toggleAppMode,
      deleteApp,
      generateNewPublicKey,
      generateNewApiKey,
    ),
    (state) => {
      return { ...state, isUpdatingApp: false };
    },
  );

  builder.addMatcher(
    deleteApp.fulfilled.match,
    (state, { meta: { arg: appId } }) => {
      return {
        ...state,
        apps: state.apps.filter((app) => app.id !== appId),
        isUpdatingApp: false,
      };
    },
  );

  builder.addMatcher(
    isFulfilled(
      updateApp,
      toggleAppMode,
      generateNewPublicKey,
      generateNewApiKey,
    ),
    (state, { payload: newApp }) => {
      return {
        ...state,
        apps: state.apps.map((app) => {
          if (app.id === newApp.id) return { ...app, ...newApp };
          return app;
        }),
        isUpdatingApp: false,
      };
    },
  );

  builder.addMatcher(withdrawCrypto.pending.match, (state) => {
    return { ...state, isWithdrawing: true };
  });

  builder.addMatcher(withdrawCrypto.rejected.match, (state) => {
    return { ...state, isWithdrawing: false };
  });

  builder.addMatcher(
    withdrawCrypto.fulfilled.match,
    (
      state,
      {
        payload: appWallet,
        meta: {
          arg: { id },
        },
      },
    ) => {
      const updatedApp = state.apps.find((app) => app.id === id) as App;
      const appWallets = updatedApp.appWallets.map((wallet) => {
        if (wallet.id === appWallet.id) return { ...wallet, ...appWallet };
        return wallet;
      });

      return {
        ...state,
        isWithdrawing: false,
        apps: state.apps.map((app) => {
          if (app.id === id) return { ...updatedApp, appWallets };
          return app;
        }),
      };
    },
  );

  builder.addMatcher(createApp.pending.match, (state) => {
    return { ...state, isCreatingApp: true };
  });

  builder.addMatcher(createApp.rejected.match, (state) => {
    return { ...state, isCreatingApp: false };
  });

  builder.addMatcher(createApp.fulfilled.match, (state, { payload: app }) => {
    return {
      ...state,
      apps: [...state.apps, app],
      isCreatingApp: false,
    };
  });

  builder.addMatcher(
    isAnyOf(fetchApps.fulfilled.match, fetchApps.rejected.match),
    (state: InitialState, { payload: apps }) => {
      const newState = { ...state };
      newState.isLoadingApps = false;
      if (apps) newState.apps = apps as any;
      return newState;
    },
  );

  builder.addMatcher(
    isPending(register, login, refreshToken, authenticate, fetchUserData),
    (state: InitialState) => {
      return { ...state, isAuthenticating: true };
    },
  );

  builder.addMatcher(updatePassword.pending.match, (state: InitialState) => {
    return { ...state, isChangingPassword: true };
  });

  builder.addMatcher(
    isAnyOf(updatePassword.rejected.match, updatePassword.fulfilled.match),
    (state: InitialState) => {
      return { ...state, isChangingPassword: false };
    },
  );

  builder.addMatcher(
    isAnyOf(
      logout.match,
      login.rejected.match,
      register.rejected.match,
      refreshToken.rejected.match,
      authenticate.rejected.match,
      fetchUserData.rejected.match,
    ),
    () => {
      window.sessionStorage.removeItem(TOKEN_KEY);
      return INITIAL_STATE;
    },
  );

  builder.addMatcher(getCompanyDetails.pending.match, (state: InitialState) => {
    return { ...state, isLoadingCompany: true };
  });

  builder.addMatcher(
    isPending(updateCompany, requestCompanyVerification),
    (state: InitialState) => {
      return { ...state, isUpdatingCompany: true };
    },
  );

  builder.addMatcher(
    isRejected(updateCompany, requestCompanyVerification),
    (state: InitialState) => {
      return { ...state, isUpdatingCompany: false };
    },
  );

  builder.addMatcher(
    isFulfilled(updateCompany, requestCompanyVerification),
    (state: InitialState, { payload: company }) => {
      return {
        ...state,
        isUpdatingCompany: false,
        company: { ...state.company, ...company },
      };
    },
  );

  builder.addMatcher(
    getCompanyDetails.rejected.match,
    (state: InitialState) => {
      return { ...state, isLoadingCompany: false };
    },
  );

  builder.addMatcher(
    getCompanyDetails.fulfilled.match,
    (state: InitialState, { payload: company }) => {
      return {
        ...state,
        isLoadingCompany: false,
        company: { ...state.company, ...company },
      };
    },
  );

  builder.addMatcher(
    addCompanyDirector.pending.match,
    (state: InitialState) => {
      return { ...state, isCreatingCompanyDirector: true };
    },
  );

  builder.addMatcher(
    addCompanyDirector.rejected.match,
    (state: InitialState) => {
      return { ...state, isCreatingCompanyDirector: false };
    },
  );
  builder.addMatcher(
    addCompanyDirector.fulfilled.match,
    (state: InitialState, { payload: director }) => {
      const { company } = state;
      if (!company) return state;

      return {
        ...state,
        company: {
          ...(company || {}),
          directors: (company?.directors || [])?.concat(director),
        },
        isCreatingCompanyDirector: false,
      };
    },
  );

  builder.addMatcher(
    updateCompanyDirector.pending.match,
    (state: InitialState) => {
      return { ...state, isUpdatingCompanyDirector: true };
    },
  );

  builder.addMatcher(
    updateCompanyDirector.rejected.match,
    (state: InitialState) => {
      return { ...state, isUpdatingCompanyDirector: false };
    },
  );

  builder.addMatcher(
    requestCompanyDirectorVerification.pending.match,
    (state: InitialState) => {
      return { ...state, isRequestingCompanyDirectorVerification: true };
    },
  );

  builder.addMatcher(
    requestCompanyDirectorVerification.rejected.match,
    (state: InitialState) => {
      return { ...state, isRequestingCompanyDirectorVerification: false };
    },
  );

  builder.addMatcher(
    requestCompanyDirectorVerification.fulfilled.match,
    (state: InitialState, { payload: companyDirector }) => {
      const { company } = state;
      if (!company) return state;

      return {
        ...state,
        isRequestingCompanyDirectorVerification: false,
        company: {
          ...(company || {}),
          directors: company?.directors?.map((director: CompanyDirector) => {
            if (director.id === companyDirector.id) return companyDirector;
            return director;
          }),
        },
      };
    },
  );

  builder.addMatcher(
    deleteCompanyDirector.pending.match,
    (state: InitialState) => {
      return { ...state, isDeletingCompanyDirector: true };
    },
  );

  builder.addMatcher(
    deleteCompanyDirector.rejected.match,
    (state: InitialState) => {
      return { ...state, isDeletingCompanyDirector: false };
    },
  );

  builder.addMatcher(
    deleteCompanyDirector.fulfilled.match,
    (state: InitialState, { payload, meta: { arg: id } }) => {
      const { company } = state;
      if (!payload || !company)
        return { ...state, isDeletingCompanyDirector: false };

      return {
        ...state,
        isDeletingCompanyDirector: false,
        company: {
          ...(company || {}),
          directors: (company?.directors || []).filter(
            (director) => director.id !== id,
          ),
        },
      };
    },
  );

  builder.addMatcher(
    updateCompanyDirector.fulfilled.match,
    (state: InitialState, { payload: companyDirector }: any) => {
      const { company } = state;
      if (!company) return state;

      return {
        ...state,
        isUpdatingCompanyDirector: false,
        company: {
          ...(company || {}),
          directors: company?.directors?.map((director: CompanyDirector) => {
            if (director.id === companyDirector.id) {
              return companyDirector;
            }

            return director;
          }),
        },
      };
    },
  );

  builder.addMatcher(
    getCompanyTeamMembers.rejected.match,
    (state: InitialState) => {
      return { ...state, isLoadingCompanyTeamMembers: false };
    },
  );

  builder.addMatcher(
    getCompanyTeamMembers.pending.match,
    (state: InitialState) => {
      return { ...state, isLoadingCompanyTeamMembers: true };
    },
  );

  builder.addMatcher(
    getCompanyTeamMembers.fulfilled.match,
    (state: InitialState, { payload: teamMembers }) => {
      return { ...state, teamMembers, isLoadingCompanyTeamMembers: false };
    },
  );

  builder.addMatcher(addTeamMember.pending.match, (state: InitialState) => {
    return { ...state, isAddingTeamMember: true };
  });

  builder.addMatcher(addTeamMember.rejected.match, (state: InitialState) => {
    return { ...state, isAddingTeamMember: false };
  });

  builder.addMatcher(
    getCompanyTransactions.pending.match,
    (state: InitialState) => {
      return { ...state, isFetchingCompanyAppTransactions: true };
    },
  );

  builder.addMatcher(
    getCompanyTransactions.rejected.match,
    (state: InitialState) => {
      return { ...state, isFetchingCompanyAppTransactions: false };
    },
  );

  builder.addMatcher(
    getCompanyTransactions.fulfilled.match,
    (state: InitialState, { payload: companyAppTransactions }) => {
      return {
        ...state,
        isFetchingCompanyAppTransactions: false,
        companyAppTransactions: {
          ...companyAppTransactions,
          data: companyAppTransactions.data,
        },
      };
    },
  );

  builder.addMatcher(
    getCompanyPayLinks.pending.match,
    (state: InitialState) => {
      return { ...state, isFetchingPayLinks: true };
    },
  );

  builder.addMatcher(
    getCompanyPayLinks.rejected.match,
    (state: InitialState) => {
      return { ...state, isFetchingPayLinks: false };
    },
  );

  builder.addMatcher(
    getCompanyPayLinks.fulfilled.match,
    (state: InitialState, { payload: payLinks }) => {
      return {
        ...state,
        isFetchingPayLinks: false,
        payLinks: {
          ...payLinks,
          data: payLinks.data,
        },
      };
    },
  );
  builder.addMatcher(getPayLinkById.pending.match, (state: InitialState) => {
    return { ...state, isFetchingPayLinks: true };
  });

  builder.addMatcher(getPayLinkById.rejected.match, (state: InitialState) => {
    return { ...state, isFetchingPayLinks: false };
  });

  builder.addMatcher(
    getPayLinkById.fulfilled.match,
    (state: InitialState, { payload }) => {
      if (!payload) return state;

      let found = false;
      const data =
        state.payLinks?.data.map((paylink) => {
          if (paylink.id === payload.id) {
            found = true;
            return payload;
          }

          return paylink;
        }) || [];

      if (!found) data.push(payload);

      return {
        ...state,
        isFetchingPayLinks: false,
        payLinks: {
          ...(state.payLinks || {
            lastPage: 1,
            totalRecords: 1,
            currentPage: 1,
            hasMorePages: false,
          }),
          data,
        },
      };
    },
  );
  builder.addMatcher(
    createPaymentLink.fulfilled.match,
    (state: InitialState, { payload }) => {
      return {
        ...state,
        isFetchingPayLinks: false,
        payLinks: {
          ...(state.payLinks || {
            lastPage: 1,
            totalRecords: 1,
            currentPage: 1,
            hasMorePages: false,
          }),
          data: [payload, ...(state.payLinks?.data || [])],
        },
      };
    },
  );

  builder.addMatcher(
    addTeamMember.fulfilled.match,
    (state: InitialState, { payload: newTeamMember }) => {
      return {
        ...state,
        isAddingTeamMember: false,
        teamMembers: [...state.teamMembers, newTeamMember],
      };
    },
  );

  builder.addMatcher(updateTeamMember.pending.match, (state: InitialState) => {
    return { ...state, isUpdatingTeamMember: true };
  });

  builder.addMatcher(updateTeamMember.rejected.match, (state: InitialState) => {
    return { ...state, isUpdatingTeamMember: false };
  });

  builder.addMatcher(
    terminateTeamMember.pending.match,
    (state: InitialState) => {
      return { ...state, isTerminatingTeamMember: true };
    },
  );

  builder.addMatcher(
    terminateTeamMember.rejected.match,
    (state: InitialState) => {
      return { ...state, isTerminatingTeamMember: false };
    },
  );

  builder.addMatcher(
    terminateTeamMember.fulfilled.match,
    (state: InitialState, { payload: newTeamMember }) => {
      return {
        ...state,
        isTerminatingTeamMember: false,
        teamMembers: state.teamMembers.map((teamMember) => {
          if (teamMember.id === newTeamMember.id)
            return { ...teamMember, ...newTeamMember };
          return teamMember;
        }),
      };
    },
  );

  builder.addMatcher(rehireTeamMember.pending.match, (state: InitialState) => {
    return { ...state, isRehiringTeamMember: true };
  });

  builder.addMatcher(rehireTeamMember.rejected.match, (state: InitialState) => {
    return { ...state, isRehiringTeamMember: false };
  });

  builder.addMatcher(
    rehireTeamMember.fulfilled.match,
    (state: InitialState, { payload: newTeamMember }) => {
      return {
        ...state,
        isRehiringTeamMember: false,
        teamMembers: state.teamMembers.map((teamMember) => {
          if (teamMember.id === newTeamMember.id)
            return { ...teamMember, ...newTeamMember };
          return teamMember;
        }),
      };
    },
  );

  builder.addMatcher(
    updateTeamMember.fulfilled.match,
    (state: InitialState, { payload: newTeamMember }) => {
      return {
        ...state,
        isUpdatingTeamMember: false,
        teamMembers: state.teamMembers.map((teamMember) => {
          if (teamMember.id === newTeamMember.id)
            return { ...teamMember, ...newTeamMember };
          return teamMember;
        }),
      };
    },
  );

  builder.addMatcher(
    setTransactionModalId.match,
    (state: InitialState, { payload }) => {
      return { ...state, transactionModalId: payload };
    },
  );

  builder.addMatcher(
    setPageTitle.match,
    (state: InitialState, { payload: { title: pageTitle } }) => {
      return pageTitle ? { ...state, pageTitle } : state;
    },
  );
};

export const reducers = {};
