import User from '@interface/user.interface';
import Company from '@interface/company.interface';
import App from '@interface/app.interface';
import {
  AppTransaction,
  AppTransactionAttributes,
} from '@interface/appTransaction.interface';
import BusinessOwnerKyc from '@interface/businessOwnerKyc.interface';
import PaginatedResult from '@interface/paginatedResult.interface';

export enum PayLinkStatusEnum {
  USED = 'used',
  UNUSED = 'unused',
  EXPIRE = 'expired',
}

export interface PayLink {
  id: string;
  amount: number;
  appName: string;
  fiatCurrency: string;
  email: string;
  status: PayLinkStatusEnum;
  createdAt: string;
  appTransaction: AppTransactionAttributes;
}

export type PaginatedPayLinks = PaginatedResult<PayLink>;

interface InitialState {
  apps: App[];
  user?: User;
  teamMembers: User[];
  payLinks?: PaginatedPayLinks;
  company?: Company;
  businessOwnerKyc?: BusinessOwnerKyc;
  isLoadingApps: boolean;
  isFetchingPayLinks: boolean;
  isFetchingCompanyAppTransactions: boolean;
  isChangingPassword: boolean;
  isCreatingApp: boolean;
  isCreatingCompanyDirector: boolean;
  isTerminatingTeamMember: boolean;
  isDeletingCompanyDirector: boolean;
  isWithdrawing: boolean;
  isUpdatingApp: boolean;
  isUpdatingUser: boolean;
  isAddingOrUpdatingBusinessOwnerKyc: boolean;
  isUpdatingCompany: boolean;
  isRequestingCompanyDirectorVerification: boolean;
  isLoadingCompanyTeamMembers: boolean;
  isUpdatingTeamMember: boolean;
  isRehiringTeamMember: boolean;
  isAddingTeamMember: boolean;
  isUpdatingCompanyDirector: boolean;
  isAuthenticated: boolean;
  isLoadingCompany: boolean;
  transactionModalId?: string;
  pageTitle: string;
  isAuthenticating: boolean;
  companyAppTransactions?: AppTransaction;
  appTransactions: { [key: string]: { [key: string]: AppTransaction } };
  isLoadingAppTransactions: { [key: string]: { [key: string]: boolean } };
}

export default InitialState;
