import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { authenticate, register } from '@action/auth.action';
import AuthContextType from '@interface/authContextType.interface';
import { ORIGIN_URL } from '@variable';

import { useAppDispatch } from './hooks.hook';

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } =
    useAuth0();
  const [redirectPath, setRedirectPath] = useState<string>();
  const [hasAuthenticated, setHasAuthenticated] = useState(false);

  useEffect(() => {
    if (isAuthenticated && !hasAuthenticated) {
      setHasAuthenticated(true);
      dispatch(
        authenticate({
          firstName: user?.given_name,
          lastName: user?.family_name,
          email: user?.email,
          externalId: user?.sub,
          isVerified: user?.email_verified,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, hasAuthenticated, dispatch]);

  const loginUser = useCallback(async () => {
    await loginWithRedirect();
  }, [loginWithRedirect]);

  const logoutUser = useCallback(async () => {
    await logout({
      logoutParams: { returnTo: ORIGIN_URL + '?action=logout' },
    });
  }, [logout]);

  const registerUser = useCallback(
    (data: any) => {
      dispatch(register(data));
    },
    [dispatch],
  );

  const value = useMemo(
    () => ({
      user: user as any,
      redirectPath,
      setRedirectPath,
      isAuthenticated,
      isAuthenticating: isLoading,
      login: loginUser,
      logout: logoutUser,
      register: registerUser,
    }),
    [
      user,
      loginUser,
      logoutUser,
      registerUser,
      redirectPath,
      setRedirectPath,
      isAuthenticated,
      isLoading,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
