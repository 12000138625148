import React, { useCallback, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';

import { AppTransactionAttributes } from '@interface/appTransaction.interface';
import AppLoader from '@component/AppLoader';
import NoActivities from '@component/NoActivities';
import TransactionStatus from '@page/Overview/TransactionStatus';
import { useAppDispatch } from '@hook/hooks.hook';
import { setTransactionModalId } from '@action/company.action';
import Spinner from '@component/Spinner';

interface TransactionsTableProps {
  hasMore: boolean;
  appTransactionLoading: boolean;
  loadMoreTransactions: () => void;
  transactions: AppTransactionAttributes[];
}

const TransactionsTable: React.FC<TransactionsTableProps> = ({
  hasMore,
  transactions = [],
  loadMoreTransactions,
  appTransactionLoading,
}) => {
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] =
    useState<keyof AppTransactionAttributes>('createdAt');

  const handleRequestSort = (property: keyof AppTransactionAttributes) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedTransactions = transactions.slice().sort((a, b) => {
    if (orderBy === 'cryptocurrency') {
      return order === 'asc'
        ? a.cryptocurrency.localeCompare(b.cryptocurrency)
        : b.cryptocurrency.localeCompare(a.cryptocurrency);
    }
    const aValue = a[orderBy] || 0;
    const bValue = b[orderBy] || 0;
    return order === 'asc'
      ? aValue < bValue
        ? -1
        : 1
      : aValue > bValue
      ? -1
      : 1;
  });

  const setTransactionId = useCallback(
    (id: string) => {
      dispatch(setTransactionModalId(id));
    },
    [dispatch],
  );

  return sortedTransactions.length ? (
    <Box px={3}>
      <TableContainer>
        <InfiniteScroll
          hasMore={hasMore}
          next={loadMoreTransactions}
          loader={<Spinner />}
          dataLength={transactions.length}
          scrollableTarget="scrollableDiv"
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sortDirection={orderBy === 'uniqueId' ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === 'uniqueId'}
                    direction={orderBy === 'uniqueId' ? order : 'asc'}
                    onClick={() => handleRequestSort('uniqueId')}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'type' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'type'}
                    direction={orderBy === 'type' ? order : 'asc'}
                    onClick={() => handleRequestSort('type')}
                  >
                    Type
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sortDirection={
                    orderBy === 'cryptocurrencyAmount' ? order : false
                  }
                >
                  <TableSortLabel
                    active={orderBy === 'cryptocurrencyAmount'}
                    direction={
                      orderBy === 'cryptocurrencyAmount' ? order : 'asc'
                    }
                    onClick={() => handleRequestSort('cryptocurrencyAmount')}
                  >
                    Amount
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sortDirection={orderBy === 'createdAt' ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === 'createdAt'}
                    direction={orderBy === 'createdAt' ? order : 'asc'}
                    onClick={() => handleRequestSort('createdAt')}
                  >
                    Created At
                  </TableSortLabel>
                </TableCell>

                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTransactions.map((transaction, index) => (
                <TableRow
                  key={index}
                  onClick={() => {
                    setTransactionId(transaction?.id);
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <Tooltip title={transaction?.uniqueId}>
                    <TableCell
                      sx={{
                        borderColor: 'grey.200',
                        textTransform: 'uppercase',
                      }}
                    >
                      <>
                        {(transaction?.uniqueId?.substring(0, 2) || '') +
                          '...' +
                          (transaction?.uniqueId?.substring(45) || '')}
                      </>
                    </TableCell>
                  </Tooltip>

                  <TableCell
                    sx={{
                      borderColor: 'grey.200',
                      textTransform: 'capitalize',
                    }}
                  >
                    {transaction.type}
                  </TableCell>

                  <TableCell>
                    {transaction.cryptocurrencyAmount}{' '}
                    {transaction.cryptocurrency}
                  </TableCell>

                  <TableCell>
                    {dayjs(transaction.createdAt).format(
                      'MMMM D, YYYY hh:mm A',
                    )}
                  </TableCell>

                  <TableCell>
                    <TransactionStatus status={transaction.status} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {appTransactionLoading && (
            <Box my={4}>
              <AppLoader size={8} />
            </Box>
          )}
        </InfiniteScroll>
      </TableContainer>
    </Box>
  ) : (
    <NoActivities
      title="No transactions yet"
      subTitle={
        <>
          You currently don’t have any transactions,
          <br />
          once you do they will appear here
        </>
      }
      alignItems="center"
      justifyContent="center"
      height="80vh"
    />
  );
};

export default TransactionsTable;
