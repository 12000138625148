import React from 'react';

import NoActivities from '@component/NoActivities';

const NoTransaction = () => {
  return (
    <NoActivities
      title="No transactions yet"
      alignItems="center"
      justifyContent="center"
      py={10}
    />
  );
};

export default NoTransaction;
