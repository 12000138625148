/** Sorted by power
 * Most powerful to the least powerful
 * */
enum UserRole {
  SUPER_ADMIN = 'superAdmin',
  ADMIN = 'admin',
  BUSINESS_OWNER = 'businessOwner',
  CUSTOMER_SUPPORT = 'customerSupport',
  BUSINESS_ACCOUNT_MANAGER = 'businessAccountManager',
  BUSINESS_ADMIN = 'businessAdmin',
  FINANCE = 'finance',
  DEVELOPER = 'developer',
  USER = 'user',
}

export default UserRole;
