import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import CompanyInfoForm from '@component/CompanyInfoForm';
import CompanyDirectorsSection from '@component/CompanyDirectorsSection';
import { getCompanyDetails, updateCompany } from '@action/company.action';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import {
  selectCompanyInfo,
  selectIsUpdatingCompany,
} from '@selector/company.selector';
import CompanyDetail from '@page/Company/CompanyDetails';
import {
  selectCanEditCompanyDetails,
  selectCanViewCompanyDirectors,
} from '@selector/permission.selector';
import { selectBusinessOwnerKyc } from '@selector/auth.selector';
import LoadingButton from '@component/LoadingButton';

const CompanyManagementPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompanyInfo);
  const canViewCompanyDirectors = useAppSelector(selectCanViewCompanyDirectors);
  const canEditCompanyDetails = useAppSelector(selectCanEditCompanyDetails);
  const [hasFetchedCompanyDirectors, setHasFetchedCompanyDirectors] =
    useState(false);
  const businessOwnerKyc = useAppSelector(selectBusinessOwnerKyc);

  const [companyName, setCompanyName] = useState(company.name);
  const isUpdatingCompany = useAppSelector(selectIsUpdatingCompany);

  useEffect(() => {
    if (canViewCompanyDirectors && !hasFetchedCompanyDirectors) {
      setHasFetchedCompanyDirectors(true);
      dispatch(getCompanyDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canViewCompanyDirectors, hasFetchedCompanyDirectors]);

  const businessOwnerKycIsVerified =
    !company?.isRegistered && businessOwnerKyc.isVerified;

  const showIsRegisteredToggle =
    !businessOwnerKycIsVerified &&
    !company.isVerified &&
    !company.verificationRequested &&
    canEditCompanyDetails;

  const disableCompanySaveButton =
    !companyName || companyName === company?.name;

  const handleSaveCompanyName = () => {
    dispatch(updateCompany({ name: companyName }));
  };

  return (
    <>
      {company?.isRegistered ? (
        <Stack px={2}>
          {company.isVerified ||
          company.verificationRequested ||
          !canEditCompanyDetails ? (
            <CompanyDetail {...company} />
          ) : (
            <CompanyInfoForm {...company} />
          )}

          <Divider
            variant="fullWidth"
            sx={{ width: 'calc(100% + 32px)', ml: '-16px', my: 5 }}
          />

          {canViewCompanyDirectors && (
            <>
              <CompanyDirectorsSection />
              <Divider
                variant="fullWidth"
                sx={{ width: 'calc(100% + 32px)', ml: '-16px', my: 5 }}
              />
            </>
          )}
        </Stack>
      ) : (
        <Stack gap={2} p={2}>
          <Typography variant="body1" fontSize="20px" fontWeight="bold">
            Company
          </Typography>

          {!showIsRegisteredToggle ? (
            <>
              <Typography variant="body1">
                You run an unregistered entity
              </Typography>
              <Typography variant="caption">
                To switch to a <b>Registered Entity</b> please contact support
                via the chatbot, or send an email to support@zinari.io
              </Typography>
            </>
          ) : (
            <>
              <Alert severity="warning">
                Once your Business Owner KYC has been verified, you will no
                longer be able to change your company name. Please ensure your
                company details are accurate before proceeding with the
                verification process.
              </Alert>

              <Box mt={2}>
                <TextField
                  variant="outlined"
                  value={companyName}
                  label="Company Name"
                  onChange={(e) => setCompanyName(e.target.value)}
                  sx={{ width: '355px' }}
                />

                <Stack alignItems="flex-end">
                  <LoadingButton
                    disabled={disableCompanySaveButton}
                    isLoading={isUpdatingCompany}
                    variant="contained"
                    onClick={handleSaveCompanyName}
                  >
                    Save
                  </LoadingButton>
                </Stack>
                <Divider
                  variant="fullWidth"
                  sx={{ width: 'calc(100% + 32px)', ml: '-16px', my: 5 }}
                />
              </Box>
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export default CompanyManagementPage;
