import React from 'react';

import TabPanel from '@component/AppDetail/TabPanel';
import AppWalletInterface from '@interface/appWallet.interface';
import WalletBalance from '@component/WalletBalance';

const AppWallet = ({
  appId,
  value,
  wallets,
  index,
}: {
  appId: string;
  value: number;
  wallets: AppWalletInterface[];
  index: number;
}) => {
  return (
    <TabPanel value={value} index={index}>
      <WalletBalance wallets={wallets} appId={appId} />
    </TabPanel>
  );
};

export default AppWallet;
