import { Button, Radio, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Check from '@mui/icons-material/CheckCircleOutlineOutlined';

const KycCheckbox = ({
  to,
  title,
  isVerified,
  verificationRequested,
}: {
  to: string;
  title: string;
  isVerified: boolean;
  verificationRequested: boolean;
}) => {
  const navigate = useNavigate();

  const handleClickRadio = (e: any) => {
    e.preventDefault();
  };

  const verificationDone = isVerified || verificationRequested;

  let text = 'Start';
  if (!isVerified && verificationRequested) text = 'Pending';
  if (isVerified) text = 'Verified';

  return (
    <Stack
      px="20px"
      py="13px"
      direction="row"
      border="1px solid"
      borderRadius="10px"
      borderColor="primary.50"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center">
        <Radio
          color="secondary"
          checkedIcon={<Check />}
          checked={verificationDone}
          onClick={handleClickRadio}
        />
        <Typography>{title}</Typography>
      </Stack>

      <Button
        variant="text"
        onClick={() => navigate(to)}
        sx={{ justifySelf: 'flex-end', p: 0 }}
        color={!isVerified && verificationRequested ? 'warning' : 'secondary'}
      >
        {text}
      </Button>
    </Stack>
  );
};

export default KycCheckbox;
