import { Box } from '@mui/material';
import { Outlet as RouterOutlet } from 'react-router-dom';
import React from 'react';

const MainContent = () => (
  <Box
    component="main"
    sx={{
      pt: '88px',
      flexGrow: 1,
    }}
  >
    <RouterOutlet />
  </Box>
);

export default MainContent;
