import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';

import CopyToClipboard from '@component/CopyToClipboard';
import TransactionStatus from '@page/Overview/TransactionStatus';
import { AppTransactionAttributes } from '@interface/appTransaction.interface';
import { TransactionType } from '@enum/transactionType.enum';

const Cell = ({
  title,
  text,
  children,
}: {
  title: string;
  text: any;
  children?: any;
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      borderTop="1px solid"
      borderColor="grey.200"
      py={2}
    >
      <Typography color="#666">{title}</Typography>

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography
          color="grey.800"
          textTransform="capitalize"
          fontWeight={600}
        >
          {text}
        </Typography>
        {children}
      </Stack>
    </Stack>
  );
};

const TransactionDetails = ({
  transaction,
}: {
  transaction?: AppTransactionAttributes;
}) => {
  if (!transaction) return null;

  return (
    <>
      {Boolean(transaction?.appName) && (
        <Cell title="App" text={transaction?.appName} />
      )}

      <Cell
        title="Transaction ID"
        text={
          (transaction?.uniqueId?.substring(0, 2) || '') +
          '...' +
          (transaction?.uniqueId?.substring(45) || '')
        }
      >
        <CopyToClipboard value={transaction?.uniqueId as string} />
      </Cell>

      <Cell
        title="Transaction Date"
        text={dayjs(transaction?.createdAt).format('hh:mm:ss A, MMMM D, YYYY')}
      />

      <Cell title={'Transaction Type'} text={transaction?.type as string} />
      <Cell
        title={
          transaction?.type === TransactionType.OUTGOING
            ? 'Amount Sent'
            : 'Amount Expected'
        }
        text={`${Number(transaction?.cryptocurrencyAmount).toLocaleString(
          'en-US',
          {
            maximumFractionDigits: 6,
          },
        )} ${transaction?.cryptocurrency}`}
      />

      {transaction?.type === TransactionType.INCOMING && (
        <Cell
          title="Amount Received"
          text={`${transaction?.amountReceived} ${transaction?.cryptocurrency}`}
        />
      )}

      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: 'grey.200',
        }}
      >
        <Cell
          title="Transaction Status"
          text={<TransactionStatus status={transaction?.status as any} />}
        />
      </Box>
    </>
  );
};

export default TransactionDetails;
