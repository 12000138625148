import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_URL, SLICE_NAME } from '@variable';
import AuthResponse from '@interface/authResponse.interface';
import User from '@interface/user.interface';

const URL = `${API_URL}/user`;

export const fetchUserData = createAsyncThunk(
  `${SLICE_NAME}/fetchUserData`,
  async (): Promise<AuthResponse> => {
    const result = await axios(URL);
    return result.data;
  },
);

export const updateUser = createAsyncThunk(
  `${SLICE_NAME}/updateUser`,
  async (data: User): Promise<AuthResponse> => {
    const result = await axios.put(URL, data);
    return result.data;
  },
);

export const agreeTerms = createAsyncThunk(
  `${SLICE_NAME}/agreeTerms`,
  async (): Promise<AuthResponse> => {
    const result = await axios.put(`${URL}/agree-terms`);
    return result.data;
  },
);
