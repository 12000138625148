import {
  Alert,
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';

import {
  ALLOWED_CRYPTOCURRENCIES,
  CRYPTO_NETWORKS,
  CRYPTOCURRENCY_ICONS,
  FIAT_SYMBOLS,
} from '@variable';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';
import { updateApp } from '@action/app.action';
import App from '@interface/app.interface';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { selectIsUpdatingApp } from '@selector/app.selector';
import LoadingButton from '@component/LoadingButton';

const arraysAreEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) return false;
  }

  return true;
};

const AppCurrencies: FC<{
  appCurrencies: CryptoCurrency[];
  id: string;
  fiatCurrency: string;
}> = ({ id, fiatCurrency, appCurrencies: initialAppCurrencies }) => {
  const [appCurrencies, setAppCurrencies] =
    useState<CryptoCurrency[]>(initialAppCurrencies);
  const isUpdatingApp = useAppSelector(selectIsUpdatingApp);
  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      await dispatch(
        updateApp({
          id,
          appCurrencies,
        } as App),
      );
    },
    [appCurrencies, dispatch, id],
  );

  useEffect(() => {
    setAppCurrencies(initialAppCurrencies);
  }, [initialAppCurrencies]);

  const handleToggleAppCurrencies = (
    checked: boolean,
    currency: CryptoCurrency,
  ) => {
    setAppCurrencies((currencies) => {
      if (currencies.length === 1 && !checked) return currencies;
      if (checked) return [...currencies, currency];
      return currencies.filter((c) => c !== currency);
    });
  };

  return (
    <>
      <Box>
        <Stack gap="12px">
          <Typography variant="body1" fontSize="20px" fontWeight="bold">
            App Currencies
          </Typography>
          <Typography variant="body1" color="grey.700">
            Control the cryptocurrencies that your app will accept
          </Typography>

          <Alert severity="info" sx={{ my: 2 }}>
            Your apps fiat currency is {fiatCurrency} (
            {FIAT_SYMBOLS[fiatCurrency]})
          </Alert>
        </Stack>

        <Stack spacing={2} mt={2} direction="column" gap={3}>
          {ALLOWED_CRYPTOCURRENCIES.map((currency) => (
            <Stack
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={1}
              direction={'row'}
              key={currency}
            >
              <Stack
                direction={'row'}
                gap={2}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Box width={40} height={40}>
                  <img
                    src={CRYPTOCURRENCY_ICONS[currency]}
                    alt={`${currency} icon`}
                    height={'auto'}
                    width={'100%'}
                  />
                </Box>
                {currency} ({CRYPTO_NETWORKS[currency]})
              </Stack>

              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={appCurrencies.includes(currency)}
                    onChange={(_, checked) =>
                      handleToggleAppCurrencies(checked, currency)
                    }
                  />
                }
                label={''}
              />
            </Stack>
          ))}
        </Stack>
      </Box>

      <Stack alignItems="flex-end">
        <LoadingButton
          isLoading={isUpdatingApp}
          variant="contained"
          onClick={onSubmit}
          size="large"
          disabled={arraysAreEqual(appCurrencies, initialAppCurrencies)}
          sx={{ width: 'fit-content', mt: 4 }}
        >
          Update Currencies
        </LoadingButton>
      </Stack>
    </>
  );
};

export default AppCurrencies;
