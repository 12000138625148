import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';

import { createApp } from '@action/app.action';
import { useAppDispatch } from '@hook/hooks.hook';
import LoadingButton from '@component/LoadingButton';
import { FIAT_CURRENCIES } from '@variable';

interface CreateAppDialogProps {
  open: boolean;
  onClose: () => void;
}

const CreateAppDialog: React.FC<CreateAppDialogProps> = ({ open, onClose }) => {
  const [appName, setAppName] = useState('');
  const [isCreatingApp, setIsCreatingApp] = useState(false);
  const [fiatCurrency, setFiatCurrency] = useState('');
  const dispatch = useAppDispatch();
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppName(event.target.value);
  };
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!appName || !fiatCurrency) return;

    setIsCreatingApp(true);
    const res = (await dispatch(
      createApp({ name: appName, fiatCurrency }),
    )) as any;

    if (!res.error) {
      setAppName('');
      setFiatCurrency('');
      onClose();
      navigate(`/dashboard/apps/${res.payload.id}`);
    }

    setIsCreatingApp(false);
  };

  const handleCancel = () => {
    setAppName(''); // Clear the input on cancel
    setFiatCurrency('');
    onClose();
  };

  const handleFiatCurrencyChange = (e: any) => {
    setFiatCurrency(e.target.value);
  };

  const disableSubmitButton = !appName || !fiatCurrency;

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle
        mt={2}
        variant="body1"
        fontSize="20px"
        fontWeight={600}
        sx={{ borderBottom: '1px solid', borderColor: 'grey.200' }}
      >
        Add New App
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 24,
          top: 32,
          color: 'grey.600',
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Stack gap={2}>
          <TextField
            margin="dense"
            label="App name"
            type="text"
            fullWidth
            value={appName}
            onChange={handleNameChange}
          />

          <TextField
            select
            value={fiatCurrency}
            onChange={handleFiatCurrencyChange}
            label="Select app currency"
            helperText={
              <Stack
                alignItems="center"
                direction="row"
                color="grey.500"
                gap={1}
                ml={-2}
                mt="12px"
              >
                <InfoOutlinedIcon color="inherit" />{' '}
                <span>
                  Note: Once you select a currency, it cannot be changed.
                </span>
              </Stack>
            }
          >
            {FIAT_CURRENCIES.map(({ code: value, name: title }, index) => (
              <MenuItem key={value + index} value={value}>
                {title}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 3, px: 2 }}>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          isLoading={isCreatingApp}
          disabled={disableSubmitButton}
        >
          Submit
        </LoadingButton>
        <Button onClick={handleCancel} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAppDialog;
