import { Field, Form, Formik } from 'formik';
import { Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import * as Yup from 'yup';
import isEqual from 'lodash.isequal';

import LoadingButton from '@component/LoadingButton';
import User from '@interface/user.interface';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { selectBusinessOwnerKyc, selectUser } from '@selector/auth.selector';
import { updateUser } from '@action/user.action';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
});

const BasicInfoSection = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const businessOwnerKyc = useAppSelector(selectBusinessOwnerKyc);
  const kycIsVerified =
    businessOwnerKyc.isVerified || businessOwnerKyc.verificationRequested;

  const initialValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
  } as User;

  const handleSubmit = async (values: User) => {
    await dispatch(updateUser(values));
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ errors, touched, isSubmitting, values, isValid }) => (
        <Form>
          <Typography variant="body1" fontSize="20px" mb={3} fontWeight="bold">
            Basic information
          </Typography>

          <Stack gap={2}>
            <Stack gap={2} direction="row">
              <Field
                as={TextField}
                name="firstName"
                label="First Name"
                fullWidth
                variant="outlined"
                sx={{ maxWidth: '370px' }}
                disabled={kycIsVerified}
                error={touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
              />

              <Field
                as={TextField}
                name="lastName"
                label="Last Name"
                sx={{ maxWidth: '370px' }}
                disabled={kycIsVerified}
                fullWidth
                variant="outlined"
                error={touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>

            <Field
              as={TextField}
              name="email"
              label="Email"
              disabled
              fullWidth
              variant="outlined"
              sx={{ maxWidth: '370px' }}
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
            />
            {!kycIsVerified && (
              <Stack alignItems="flex-end">
                <LoadingButton
                  type="submit"
                  color="primary"
                  size="large"
                  disabled={
                    !isValid ||
                    isEqual(initialValues, {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      email: values.email,
                    })
                  }
                  isLoading={isSubmitting}
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </Stack>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default BasicInfoSection;
