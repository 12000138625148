import { Box, Typography } from '@mui/material';

import payLinkDone from '@asset/images/paylinkdone.webp';

const PayLinkSent = () => {
  return (
    <Box textAlign="center" width={400}>
      <img width={116} height={'auto'} src={payLinkDone} alt="PayLink sent" />
      <Typography color="secondary.600" mt={4} mb={2}>
        Link sent successfully!!!
      </Typography>
      <Typography>
        Link created successfully and sent
        <br /> to the customer&apos;s email.
      </Typography>
    </Box>
  );
};

export default PayLinkSent;
