import { Field, Form, useFormikContext } from 'formik';
import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material';
import GroupRemoveRoundedIcon from '@mui/icons-material/GroupRemoveRounded';
import isEqual from 'lodash.isequal';
import React from 'react';

import { USER_ROLES } from '@variable';
import LoadingButton from '@component/LoadingButton';
import User from '@interface/user.interface';

const TeamMemberDetailForm = ({
  isEditing,
  isTerminated,
  initialValues,
  onSetRehireDialogOpen,
  isRehiringTeamMember,
}: any) => {
  const { errors, touched, isValid, isSubmitting, values } =
    useFormikContext<User>();

  return (
    <Form>
      {isEditing && (
        <Box position="relative">
          <Box position="absolute" right={0} top={-40}>
            {isTerminated && (
              <Box
                alignItems="center"
                flexDirection="row"
                display="flex"
                gap={1}
              >
                <Typography color="error">User Terminated</Typography>
                <GroupRemoveRoundedIcon color="error" />
              </Box>
            )}
          </Box>
        </Box>
      )}

      <Stack gap={3}>
        <Stack gap={3} direction="row">
          <Field
            disabled={isTerminated}
            as={TextField}
            name="firstName"
            label="First Name"
            fullWidth
            sx={{ maxWidth: '370px' }}
            variant="outlined"
            error={touched.firstName && !!errors.firstName}
            helperText={touched.firstName && errors.firstName}
          />
          <Field
            disabled={isTerminated}
            as={TextField}
            name="lastName"
            label="Last Name"
            fullWidth
            sx={{ maxWidth: '370px' }}
            variant="outlined"
            error={touched.lastName && !!errors.lastName}
            helperText={touched.lastName && errors.lastName}
          />
        </Stack>

        <Stack gap={3} direction="row">
          <Field
            disabled={isTerminated || isEditing}
            as={TextField}
            name="email"
            label="Email"
            fullWidth
            sx={{ maxWidth: '370px' }}
            variant="outlined"
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
          />

          <Field
            disabled={isTerminated}
            as={TextField}
            name="role"
            label="Role"
            select
            fullWidth
            sx={{ maxWidth: '370px' }}
            variant="outlined"
            error={touched.role && !!errors.role}
            helperText={touched.role && errors.role}
          >
            {USER_ROLES.map(({ value, title }) => (
              <MenuItem key={value} value={value}>
                {title}
              </MenuItem>
            ))}
          </Field>
        </Stack>
      </Stack>

      {!isTerminated && (
        <Box mt={3} display="flex" justifyContent="flex-end">
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isValid || isEqual(values, initialValues)}
            isLoading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Box>
      )}

      {isTerminated && (
        <Box mt={3} display="flex" justifyContent="flex-end">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => {
              onSetRehireDialogOpen(true);
            }}
            isLoading={isRehiringTeamMember}
          >
            Rehire
          </LoadingButton>
        </Box>
      )}
    </Form>
  );
};

export default TeamMemberDetailForm;
