import { Box, Typography } from '@mui/material';
import React from 'react';

import { AppTransactionStatus } from '@enum/appTransactionStatus.enum';

const TransactionStatus = ({ status }: { status: AppTransactionStatus }) => {
  let bgcolor = 'success.50';
  let color = 'success.600';

  if (status === AppTransactionStatus.FAILED) {
    bgcolor = 'error.50';
    color = 'error.600';
  } else if (status === AppTransactionStatus.EXCESS) {
    bgcolor = '#D4E9FF';
    color = '#298FFF';
  } else if (status === AppTransactionStatus.INCOMPLETE) {
    bgcolor = 'warning.50';
    color = 'warning.600';
  } else if (status === AppTransactionStatus.PENDING) {
    bgcolor = 'grey.50';
    color = 'grey.600';
  }

  return (
    <Box width="25%">
      <Box
        bgcolor={bgcolor}
        width="fit-content"
        px={1}
        py={0.5}
        borderRadius="4px"
        minWidth="90px"
        textAlign="center"
      >
        <Typography color={color} textTransform="capitalize">
          {status}
        </Typography>
      </Box>
    </Box>
  );
};

export default TransactionStatus;
