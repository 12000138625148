import React from 'react';
import { Box, Typography } from '@mui/material';

import { PayLinkStatusEnum } from '@interface/initialState.interface';

const PayLinkStatus = ({ status }: { status: PayLinkStatusEnum }) => {
  let bgcolor = 'success.50';
  let color = 'success.600';

  if (status === PayLinkStatusEnum.EXPIRE) {
    bgcolor = 'error.50';
    color = 'error.600';
  } else if (status === PayLinkStatusEnum.UNUSED) {
    bgcolor = '#D4E9FF';
    color = '#298FFF';
  }

  return (
    <Box width="25%">
      <Box
        bgcolor={bgcolor}
        width="fit-content"
        px={1}
        py={0.5}
        borderRadius="4px"
        minWidth="90px"
        textAlign="center"
      >
        <Typography color={color} textTransform="capitalize">
          {status}
        </Typography>
      </Box>
    </Box>
  );
};

export default PayLinkStatus;
