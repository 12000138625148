import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Auth0Provider } from '@auth0/auth0-react';

import { store } from '@store';
import DisplayEnvironmentRole from '@component/DisplayEnvironmentRole';
import { AuthProvider } from '@hook/useAuth.hook';
import {
  AUTH_0_AUDIENCE,
  AUTH_0_CLIENT_ID,
  AUTH_0_DOMAIN,
  ORIGIN_URL,
} from '@variable';
import { TawkToProvider } from '@hook/useTawkTo.hook';

import App from './App';
import theme from './theme';
import { Axios } from './axiosInterceptor';

// eslint-disable-next-line import/no-named-as-default-member
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Auth0Provider
    domain={AUTH_0_DOMAIN}
    clientId={AUTH_0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: ORIGIN_URL,
      audience: AUTH_0_AUDIENCE,
    }}
  >
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider>
            <Axios />
            <TawkToProvider>
              <App />
            </TawkToProvider>
            <DisplayEnvironmentRole />
          </AuthProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
