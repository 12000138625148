import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as Link } from '@asset/icons/link.svg';
import { ReactComponent as File } from '@asset/icons/file.svg';
import { ReactComponent as Plug } from '@asset/icons/plug.svg';

import ActionCard from './ActionCard';

const ACTIONS = [
  {
    icon: <Link />,
    title: 'Share a payment link',
    description: 'to start receiving payments instantly.',
    to: '/dashboard/paylink',
  },
  {
    icon: <Plug />,
    title: 'Create an application',
    description: 'to kickoff integrations.',
    to: '/dashboard/apps',
  },
  {
    icon: <File />,
    title: 'Check the documentation',
    description: 'for more solutions and insights.',
    to: 'https://pay.zinari.io/developers',
    blank: true,
  },
];

const HeroSection = ({
  showIsRegisteredToggle,
  title,
}: {
  showIsRegisteredToggle: boolean;
  title: string;
}) => {
  return (
    <Box pt={showIsRegisteredToggle ? 2 : 4} px={3}>
      <Stack direction="row" justifyContent="space-between">
        <Box maxWidth="328px">
          <Typography variant="h5" fontWeight={600} mb={2}>
            <span style={{ marginRight: 16 }}>👋</span>
            {title}
          </Typography>
          <Typography variant="body1" color="grey.800">
            Here’s a brief overview of your dashboard and activities.
          </Typography>
        </Box>

        <Stack gap={3} direction="row">
          {ACTIONS.map((action) => (
            <ActionCard key={action.title} {...action} />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default HeroSection;
