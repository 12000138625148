import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import App from '@interface/app.interface';
import { AppMode } from '@enum/appMode.enum';
import { toggleAppMode } from '@action/app.action';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { selectIsUpdatingApp } from '@selector/app.selector';
import { selectCompanyInfo } from '@selector/company.selector';

import BasicInfo from './BasicInfo';
import AppWallet from './AppWallet';
import ApiInfo from './ApiInfo';

const TABS = ['Information', 'API', 'App Wallet'];

const AppDetail: FC<App> = ({
  id,
  mode,
  name,
  logoUrl,
  appWallets: wallets,
  appCurrencies,
  publicKey,
  transactionDetectedWebhookUrl,
  transactionDoneWebhookUrl,
  incompletePaymentCompletionWebhookUrl,
  customerPaysTax,
  whitelistedUrls,
  customerPaysFees,
  fiatCurrency,
  customAuthorizationKey,
  apiKey,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsTab = Number(searchParams.get('tab') || 0);
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(searchParamsTab);
  const company = useAppSelector(selectCompanyInfo);
  useEffect(() => {
    setSearchParams({ tab: String(value) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const isUpdatingApp = useAppSelector(selectIsUpdatingApp);

  const dispatch = useAppDispatch();

  const handleModeChange = () => {
    if (!isUpdatingApp) {
      dispatch(toggleAppMode(id));
    }
  };

  const inProduction = mode === AppMode.PRODUCTION;

  return (
    <>
      <Stack
        px={2}
        py={2}
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          color="primary"
          size="small"
          variant="text"
          startIcon={<ChevronLeft />}
          onClick={() => navigate('/dashboard/apps')}
          sx={{ p: 0 }}
        >
          Back
        </Button>
      </Stack>

      <Box
        sx={{
          position: 'relative',
          px: 2,
        }}
      >
        <Box position="absolute" top={0} right={0} zIndex={1}>
          <FormControlLabel
            disabled={isUpdatingApp}
            control={
              <Switch
                checked={inProduction}
                onChange={handleModeChange}
                disabled={isUpdatingApp}
                color="secondary"
              />
            }
            label={
              <Typography variant="body2" color="grey.800">
                {inProduction ? 'Production' : 'Development'}
              </Typography>
            }
          />
        </Box>

        <Box
          sx={{
            px: 2,
            position: 'relative',
            borderBottom: '1px solid',
            borderColor: 'primary.100',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="app detail tabs"
            scrollButtons="auto"
            visibleScrollbar
            TabIndicatorProps={{
              sx: {
                height: '3px',
                bgcolor: 'secondary.600',
                borderRadius: '4px 4px 0px 0px',
              },
            }}
          >
            {TABS.map((tab) => (
              <Tab label={tab} key={tab} sx={{ textTransform: 'capitalize' }} />
            ))}
          </Tabs>
        </Box>
      </Box>

      <Box pt={2} px={3} pb={10}>
        <BasicInfo
          id={id}
          index={0}
          name={name}
          value={value}
          logoUrl={logoUrl}
          customerPaysTax={customerPaysTax}
          customerPaysFees={customerPaysFees}
          taxRate={company.taxRate as number}
          feeRate={company.feeRate as number}
        />

        <ApiInfo
          id={id}
          value={value}
          publicKey={publicKey}
          transactionDoneWebhookUrl={transactionDoneWebhookUrl}
          transactionDetectedWebhookUrl={transactionDetectedWebhookUrl}
          customAuthorizationKey={customAuthorizationKey}
          incompletePaymentCompletionWebhookUrl={
            incompletePaymentCompletionWebhookUrl
          }
          appCurrencies={appCurrencies}
          fiatCurrency={fiatCurrency}
          whitelistedUrls={whitelistedUrls}
          apiKey={apiKey}
          index={1}
        />

        <AppWallet value={value} wallets={wallets} index={2} appId={id} />
      </Box>
    </>
  );
};

export default AppDetail;
