import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { selectTransactionModalId } from '@selector/app.selector';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { setTransactionModalId } from '@action/company.action';
import { selectCompanyAppTransactionById } from '@selector/company.selector';
import TransactionDetails from '@component/TransactionDetails';

const TransactionModal = () => {
  const dispatch = useAppDispatch();
  const transactionModalId = useAppSelector(selectTransactionModalId);
  const transaction = useAppSelector((state) =>
    selectCompanyAppTransactionById(state, transactionModalId),
  );
  const [isOpen, setIsOpen] = useState(Boolean(transactionModalId));

  useEffect(() => {
    setIsOpen(Boolean(transactionModalId));
  }, [transactionModalId]);

  const onClose = () => {
    dispatch(setTransactionModalId());
  };

  const openBlockchainTransactionDetails = () => {
    if (!transaction?.blockchainTransactionId) return;

    window.open(
      'https://tronscan.org/#/transaction/' +
        transaction?.blockchainTransactionId,
      '_blank',
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle variant="body1" fontSize="20px" fontWeight={600} mt={2}>
        Transaction Details
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={onClose}
        size="small"
        sx={{
          position: 'absolute',
          right: 8,
          top: 25,
          color: 'grey.600',
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ py: 0 }}>
        <TransactionDetails transaction={transaction} />
      </DialogContent>

      <DialogActions
        sx={{
          py: 3,
          px: 2,
        }}
      >
        {transaction?.blockchainTransactionId && (
          <Button
            color="info"
            variant="contained"
            onClick={openBlockchainTransactionDetails}
            sx={{
              bgcolor: 'primary.50',
              color: 'primary.900',
              boxShadow: 0,
              fontWeight: 500,
            }}
          >
            View on Blockchain
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TransactionModal;
