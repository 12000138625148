import { Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useAuth } from '@hook/useAuth.hook';

import AppLoader from '../AppLoader';

const UnprotectedRoute = ({ children }: any) => {
  const { redirectPath, setRedirectPath } = useAuth();
  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) setRedirectPath();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (isAuthenticated) {
    let path = redirectPath;
    if (!path) path = '/dashboard/overview';
    return <Navigate to={path} />;
  }

  if (isLoading) return <AppLoader />;
  return children;
};

export default UnprotectedRoute;
