import React from 'react';
import { Divider, List, ListItemButton, ListItemText } from '@mui/material';

interface PreviouslyUsedAddressesProps {
  addresses: string[];
  onSelectAddress: (address: string) => void;
}

const PreviouslyUsedAddresses: React.FC<PreviouslyUsedAddressesProps> = ({
  addresses,
  onSelectAddress,
}) => {
  return addresses.length ? (
    <List>
      {addresses.map((address, index) => (
        <React.Fragment key={index}>
          <ListItemButton onClick={() => onSelectAddress(address)}>
            <ListItemText
              primary={address}
              secondary={`Last used on ${new Date().toLocaleDateString()}`}
            />
          </ListItemButton>
          {index < addresses.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  ) : null;
};

export default PreviouslyUsedAddresses;
