import { Container, Link, Stack, Typography } from '@mui/material';
import React from 'react';

import notVerified from '@asset/icons/notverified.webp';
import { useAuth } from '@hook/useAuth.hook';

const NotVerified = ({ email }: { email?: string }) => {
  const { logout } = useAuth();

  return (
    <Container
      sx={{
        pt: 20,
        width: '100vw',
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
      }}
    >
      <img
        width={190}
        height="auto"
        alt="zinaripay logo"
        src={'/logoDefault.webp'}
        style={{ position: 'absolute', top: 32, left: 0 }}
      />
      <Stack
        gap={3}
        textAlign="center"
        justifyContent="center"
        alignItems="center"
      >
        <img
          width={80}
          height="auto"
          src={notVerified}
          alt="not verified icon"
        />
        <Typography variant="h5" fontWeight={500} maxWidth={830}>
          Your email has not yet been verified. Please check your inbox at{' '}
          {email} for a verification link.
        </Typography>
        <Typography>It may have been filtered to your spam folder.</Typography>
        <Typography>
          If you want to sign in to another account, then click on{' '}
          <Link onClick={logout} sx={{ fontWeight: 700 }}>
            this link
          </Link>
          .
        </Typography>
      </Stack>
    </Container>
  );
};

export default NotVerified;
