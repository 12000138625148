import React from 'react';
import { Box, useMediaQuery } from '@mui/material';

import Sidebar from '@component/Sidebar';
import Header from '@component/DashboardLayout/Header';
import MainContent from '@component/DashboardLayout/MainContent';
import TransactionModal from '@component/TransactionModal';
import UseDesktop from '@component/UseDesktop';

const DashboardLayout = () => {
  const isMobile = useMediaQuery('(max-width:999px)');

  if (!isMobile)
    return (
      <Box sx={{ display: 'flex' }}>
        <Header />
        <Sidebar />
        <MainContent />
        <TransactionModal />
      </Box>
    );

  return <UseDesktop />;
};

export default DashboardLayout;
