import { Stack, Typography } from '@mui/material';
import React from 'react';

import noTransactions from '@asset/images/noTransactions.webp';

const NoActivities = ({
  children,
  title,
  subTitle,
  ...others
}: {
  children?: any;
  title: string;
  subTitle?: any;
  [key: string]: any;
}) => (
  <Stack
    gap={2}
    alignItems="center"
    textAlign="center"
    justifyContent="center"
    {...others}
  >
    <img src={noTransactions} height={60} width={60} alt="no activity" />

    <Typography variant="body1" fontSize="18px" color="grey.600">
      {title}
    </Typography>

    {subTitle && (
      <Typography variant="body1" color="grey.500" mb={4}>
        {subTitle}
      </Typography>
    )}

    {children}
  </Stack>
);

export default NoActivities;
