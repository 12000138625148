import React, { FC } from 'react';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '@hook/hooks.hook';
import {
  selectCanViewOverviewPage,
  selectCanViewSidebarApps,
  selectCanViewSidebarAppTransactions,
  selectCanViewSidebarTeams,
} from '@selector/permission.selector';
import useTawkTo from '@hook/useTawkTo.hook';
import { selectCompanyInfo } from '@selector/company.selector';
import { ReactComponent as Apps } from '@asset/icons/apps.svg';
import { ReactComponent as Overview } from '@asset/icons/overview.svg';
import { ReactComponent as Paylink } from '@asset/icons/paylink.svg';
import { ReactComponent as Team } from '@asset/icons/team.svg';
import { ReactComponent as Transactions } from '@asset/icons/transactions.svg';
import { ReactComponent as Settings } from '@asset/icons/settings.svg';
import { ReactComponent as Documentation } from '@asset/icons/documentation.svg';
import { ReactComponent as Support } from '@asset/icons/support.svg';

const Sidebar: FC = () => {
  const { maximize: maximizeTawkTo }: any = useTawkTo();
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation =
    location.pathname.replace('/dashboard', '').replace('/', '') || 'overview';
  const company = useAppSelector(selectCompanyInfo);
  const canViewSidebarApps = useAppSelector(selectCanViewSidebarApps);
  const canViewSidebarTeams = useAppSelector(selectCanViewSidebarTeams);
  const canViewOverviewPage = useAppSelector(selectCanViewOverviewPage);
  const canViewSidebarAppTransactions = useAppSelector(
    selectCanViewSidebarAppTransactions,
  );

  const topMenuItems: any = (() =>
    [
      canViewOverviewPage
        ? { text: 'Overview', icon: <Overview />, to: 'overview' }
        : null,
      canViewSidebarApps ? { text: 'Apps', icon: <Apps />, to: 'apps' } : null,
      { text: 'PayLink', icon: <Paylink />, to: 'paylink' },
      canViewSidebarAppTransactions
        ? {
            text: 'Transactions',
            to: 'app-transactions',
            icon: <Transactions />,
          }
        : null,
      canViewSidebarTeams ? { text: 'Team', icon: <Team />, to: 'team' } : null,
    ].filter(Boolean))();

  const bottomMenuItems = [
    { text: 'Settings', icon: <Settings />, to: 'settings' },
    {
      text: 'Documentation',
      icon: <Documentation />,
      onClick: () => {
        window.open('https://pay.zinari.io/developers', '_blank');
      },
    },
    {
      text: 'Support',
      icon: <Support />,
      onClick: maximizeTawkTo,
    },
  ];

  const listButtonStyle = ({ to }: { index: number; to?: string }) => {
    const isCurrentLocation =
      to && new RegExp(to as string).test(currentLocation);

    return {
      p: 0,
      mb: 2,
      color: isCurrentLocation ? 'secondary.400' : 'rgba(225, 225, 225, 0.8)',
      '&:hover': {
        color: 'secondary.400',
      },
    };
  };

  const getPadStyle = (to?: string) => {
    const isCurrentLocation =
      to && new RegExp(to as string).test(currentLocation);

    return {
      display: isCurrentLocation ? 'block' : 'none',
      height: '16px',
      width: '3px',
      borderRadius: '0 10px 10px 0',
      bgcolor: 'secondary.400',
      position: 'absolute',
      left: '-20px',
    };
  };

  return (
    <Drawer
      open
      variant="permanent"
      sx={{
        flexShrink: 0,
        width: '228px',
        whiteSpace: 'nowrap',
        '& .MuiDrawer-paper': {
          width: '228px',
          transition: 'width 0.5s',
        },
      }}
    >
      <Box
        sx={{
          pt: 4,
          pb: 8,
          px: '20px',
          height: '-webkit-fill-available',
          bgcolor: 'primary.900',
        }}
      >
        <Typography mb={4} fontSize={18} fontWeight={600} color={'grey.300'}>
          {company.name}
        </Typography>

        <List sx={{ height: `calc(100% - 40px)`, position: 'relative', p: 0 }}>
          <Box mb={2}>
            <Typography color={'grey.500'} variant={'caption'}>
              MAIN
            </Typography>
          </Box>
          {topMenuItems.map(
            ({ text, icon, to, onClick }: any, index: number) => (
              <Tooltip arrow key={text} title={text} placement="right">
                <ListItemButton
                  onClick={onClick ? onClick : () => navigate(to as string)}
                  sx={listButtonStyle({ index, to })}
                >
                  <Box sx={getPadStyle(to)} />

                  <ListItemIcon
                    sx={{ color: 'inherit', mr: '10px', minWidth: 0 }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </Tooltip>
            ),
          )}

          <Divider
            variant="fullWidth"
            sx={{ borderColor: 'rgba(225, 225, 225, 0.8)', mb: '28px' }}
          />

          <Box mb={2}>
            <Typography color={'grey.500'} variant={'caption'}>
              ACCOUNT
            </Typography>
          </Box>

          {bottomMenuItems.map(
            ({ text, icon, to, onClick }: any, index: number) => (
              <Tooltip arrow key={text} title={text} placement="right">
                <ListItemButton
                  onClick={onClick ? onClick : () => navigate(to as string)}
                  sx={listButtonStyle({ index, to })}
                >
                  <Box sx={getPadStyle(to)} />

                  <ListItemIcon
                    sx={{ color: 'inherit', mr: '20px', minWidth: 0 }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </Tooltip>
            ),
          )}
          <img
            src="/logo.webp"
            alt="zinaripay logo"
            width={140}
            height="auto"
            style={{ position: 'absolute', bottom: 0 }}
          />
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
