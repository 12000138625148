import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import BusinessOwnerKyc from '@interface/businessOwnerKyc.interface';
import BusinessForm from '@page/Settings/BusinessForm';
import { addOrUpdateBusinessOwnerKyc } from '@action/company.action';
import { useAppDispatch } from '@hook/hooks.hook';

const validationSchema = Yup.object({
  dateOfBirth: Yup.date()
    .required('Date of Birth is required')
    .test(
      'age',
      'You must be at least 18 years old',
      (value) =>
        value && new Date().getFullYear() - new Date(value).getFullYear() >= 18,
    ),
  nationality: Yup.string().required('Nationality is required'),
  street: Yup.string().required('Street is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('Region is required'),
  idUpload: Yup.string().required('ID is required'),
  utilityBillUpload: Yup.string().required('Utility Bill is required'),
});

const BusinessOwnerKycForm: React.FC<BusinessOwnerKyc> = ({
  firstName,
  lastName,
  dateOfBirth,
  nationality,
  idUpload,
  email,
  phoneNumber,
  countryOfResidence,
  street,
  city,
  state,
  utilityBillUpload,
  isVerified,
}) => {
  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    async (values: BusinessOwnerKyc) => {
      await dispatch(addOrUpdateBusinessOwnerKyc(values));
    },
    [dispatch],
  );

  const initialValues = {
    firstName: firstName || '',
    lastName: lastName || '',
    dateOfBirth: dateOfBirth || '',
    nationality: nationality || 'NG',
    idUpload: idUpload || '',
    countryOfResidence: countryOfResidence || '',
    email: email || '',
    phoneNumber: phoneNumber || '',
    street: street || '',
    city: city || '',
    state: state || '',
    utilityBillUpload: utilityBillUpload || '',
  } as BusinessOwnerKyc;

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {() => (
        <Form>
          <BusinessForm
            initialValues={initialValues}
            isVerified={Boolean(isVerified)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default BusinessOwnerKycForm;
