import { Box } from '@mui/material';
import React from 'react';

const CustomAlert = ({ children }: { children: any }) => {
  return (
    <Box
      p={1}
      borderRadius={1}
      bgcolor="warning.50"
      color="warning.600"
      width="fit-content"
    >
      {children}
    </Box>
  );
};

export default CustomAlert;
