import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useAuth0 } from '@auth0/auth0-react';

import { IS_PROD, TAWKTO_PROPERTY_ID, TAWKTO_WIDGET_ID } from '@variable';

interface TawkToInterface {
  isChatHidden: boolean;
  minimize: () => void;
  maximize: () => void;
  hideWidget: () => void;
  showWidget: () => void;
}

interface TawkToContextAttributes {
  minimize: () => void;
  hide: () => void;
  show: () => void;
  maximize: () => void;
}

const TawkToContext = createContext<TawkToContextAttributes>(
  {} as TawkToContextAttributes,
);

export const TawkToProvider = ({ children }: any) => {
  const tawkMessengerRef = useRef<TawkToInterface>();
  const { isAuthenticated } = useAuth0();

  const minimize = useCallback(() => {
    tawkMessengerRef.current?.minimize?.();
  }, [tawkMessengerRef]);

  const maximize = useCallback(() => {
    tawkMessengerRef.current?.maximize?.();
  }, [tawkMessengerRef]);

  const hide = useCallback(() => {
    tawkMessengerRef.current?.hideWidget?.();
  }, [tawkMessengerRef]);

  const show = useCallback(() => {
    tawkMessengerRef.current?.showWidget?.();
  }, [tawkMessengerRef]);

  useEffect(() => {
    if (isAuthenticated) hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const value = useMemo(
    () => ({ minimize, hide, show, maximize }),
    [minimize, hide, show, maximize],
  );

  return (
    <TawkToContext.Provider value={value}>
      {children}
      {IS_PROD && (
        <TawkMessengerReact
          ref={tawkMessengerRef}
          propertyId={TAWKTO_PROPERTY_ID}
          widgetId={TAWKTO_WIDGET_ID}
        />
      )}
    </TawkToContext.Provider>
  );
};

const useTawkTo = () => useContext(TawkToContext);

export default useTawkTo;
