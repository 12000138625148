import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '@hook/hooks.hook';
import {
  selectCompanyAppTransactions,
  selectIsFetchingCompanyAppTransactions,
} from '@selector/company.selector';
import { getCompanyTransactions } from '@action/company.action';
import Spinner from '@component/Spinner';

import Transactions from './Transactions';
import NoTransaction from './NoTransaction';

const RecentTransactions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const companyAppTransactions = useSelector(selectCompanyAppTransactions);
  const isFetchingCompanyAppTransactions = useSelector(
    selectIsFetchingCompanyAppTransactions,
  );

  useEffect(() => {
    dispatch(getCompanyTransactions({ page: 1, limit: 5 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      width="100%"
      border="1px solid"
      borderRadius="15px"
      borderColor="primary.50"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        pt={3}
        pb={2}
        px={2}
      >
        <Typography variant="body1" fontSize="18px">
          Recent Transactions
        </Typography>

        {Boolean(companyAppTransactions?.length) && (
          <Button
            variant="text"
            color="secondary"
            sx={{ justifySelf: 'flex-end', p: 0 }}
            onClick={() => navigate('/dashboard/app-transactions')}
          >
            View all
          </Button>
        )}
      </Stack>

      {isFetchingCompanyAppTransactions && (
        <Stack alignItems="center" justifyContent="center" py={10} gap={2}>
          <Spinner />
        </Stack>
      )}

      {!isFetchingCompanyAppTransactions && !companyAppTransactions?.length && (
        <NoTransaction />
      )}

      {!isFetchingCompanyAppTransactions &&
        Boolean(companyAppTransactions?.length) && (
          <Transactions
            transactions={companyAppTransactions?.slice(0, 5) || []}
          />
        )}
    </Box>
  );
};

export default RecentTransactions;
