import * as Yup from 'yup';

import { CompanyDirector } from '@interface/company.interface';
import { PHONE_NUMBER_REGEX } from '@variable';

export const getInitialValues = (director?: CompanyDirector | null) => ({
  id: director?.id || '',
  fullName: director?.fullName || '',
  dateOfBirth: director?.dateOfBirth || '',
  nationality: director?.nationality || 'NG',
  idUpload: director?.idUpload || '',
  email: director?.email || '',
  phoneNumber: director?.phoneNumber || '',
  countryOfResidence: director?.countryOfResidence || '',
  street: director?.street || '',
  city: director?.city || '',
  state: director?.state || '',
  utilityBillUpload: director?.utilityBillUpload || '',
});

export const validationSchema = [
  Yup.object({
    fullName: Yup.string().required('Full Name is required'),
    dateOfBirth: Yup.date()
      .required('Date of Birth is required')
      .test(
        'age',
        'You must be at least 18 years old',
        (value) =>
          value &&
          new Date().getFullYear() - new Date(value).getFullYear() >= 18,
      ),
    nationality: Yup.string().required('Nationality is required'),
  }),
  Yup.object({
    idUpload: Yup.mixed().required('ID upload is required'),
  }),
  Yup.object({
    countryOfResidence: Yup.string().required(
      'Country Of Residence is required',
    ),
    street: Yup.string().required('Street is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('Region is required'),
    utilityBillUpload: Yup.mixed().required('Utility Bill upload is required'),
  }),
  Yup.object({
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email address'),
    phoneNumber: Yup.string()
      .required('Phone Number is required')
      .matches(PHONE_NUMBER_REGEX, 'Phone Number format is invalid'),
  }),
];
