import React from 'react';
import { TextField } from '@mui/material';
import { Field, useFormikContext } from 'formik';

import PhoneNumberField from '@component/PhoneNumberField';
import { CompanyDirector } from '@interface/company.interface';

const ContactInformationStep = ({ isReadonly }: { isReadonly: boolean }) => {
  const { touched, errors, values } = useFormikContext<CompanyDirector>();

  return (
    <>
      <Field
        as={TextField}
        disabled={isReadonly}
        name="email"
        label="Email"
        fullWidth
        margin="normal"
        variant="outlined"
        helperText={touched.email && errors.email}
        error={touched.email && Boolean(errors.email)}
      />
      <PhoneNumberField
        as={TextField}
        disabled={isReadonly}
        name="phoneNumber"
        label="Phone Number"
        fullWidth
        margin="normal"
        variant="outlined"
        country={values.countryOfResidence}
        helperText={touched.phoneNumber && errors.phoneNumber}
        error={touched.phoneNumber && Boolean(errors.phoneNumber)}
      />
    </>
  );
};

export default ContactInformationStep;
