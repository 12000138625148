import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { selectApp, selectIsLoadingApps } from '@selector/app.selector';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import AppDetail from '@component/AppDetail';
import { fetchApps } from '@action/app.action';
import AppLoader from '@component/AppLoader';
import { setPageTitle } from '@action/company.action';
import NoActivities from '@component/NoActivities';

const ViewCreatedApp = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const app = useAppSelector((state) => selectApp(state, id as string));
  const isLoadingApps = useAppSelector(selectIsLoadingApps);

  useEffect(() => {
    if (!app) dispatch(fetchApps());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (app?.name) dispatch(setPageTitle(app.name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.name]);

  if (isLoadingApps) return <AppLoader />;

  if (!app) return <NoActivities title="App Not Found"></NoActivities>;

  return <AppDetail {...app} />;
};

export default ViewCreatedApp;
