import React, { useCallback, useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import GroupRemoveRoundedIcon from '@mui/icons-material/GroupRemoveRounded';
import { ChevronLeft } from '@mui/icons-material';

import User from '@interface/user.interface';
import {
  addTeamMember,
  getCompanyTeamMembers,
  rehireTeamMember,
  setPageTitle,
  terminateTeamMember,
  updateTeamMember,
} from '@action/company.action';
import { useAppDispatch } from '@hook/hooks.hook';
import UserRole from '@enum/userRole.enum';
import LoadingButton from '@component/LoadingButton';
import UserStatus from '@enum/userStatus.enum';
import ConfirmationDialog from '@component/ConfirmationDialog';
import TeamMemberDetailForm from '@component/TeamMemberDetail/TeamMemberDetailForm';

interface TeamMemberDetailProps {
  member?: User;
  isEditing: boolean;
}

const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  role: Yup.string().required('Role is required'),
});

const TeamMemberDetail: React.FC<TeamMemberDetailProps> = ({
  member,
  isEditing,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isTerminatingTeamMember, setIsTerminatingTeamMember] = useState(false);
  const [isRehiringTeamMember, setIsRehiringTeamMember] = useState(false);

  const isTerminated = member?.status === UserStatus.TERMINATED;

  useEffect(() => {
    dispatch(getCompanyTeamMembers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    async (values: User) => {
      const action = member ? updateTeamMember : addTeamMember;
      const res = (await dispatch(
        action({
          id: member?.id,
          role: values.role,
          email: values.email,
          lastName: values.lastName,
          firstName: values.firstName,
        } as User),
      )) as any;

      if (!res.error) {
        navigate(`/dashboard/team/${res.payload.id}`);
      }
    },
    [dispatch, member, navigate],
  );

  const handleTerminateTeamMember = useCallback(async () => {
    setIsTerminatingTeamMember(true);
    if (member?.id) await dispatch(terminateTeamMember(member?.id as string));
    setIsTerminatingTeamMember(false);
  }, [dispatch, member]);

  const handleRehireTeamMember = useCallback(async () => {
    setIsRehiringTeamMember(true);
    if (member?.id) await dispatch(rehireTeamMember(member?.id as string));
    setIsRehiringTeamMember(false);
  }, [dispatch, member]);

  const initialValues =
    member ||
    ({
      firstName: '',
      lastName: '',
      email: '',
      role: UserRole.CUSTOMER_SUPPORT,
    } as User);

  const [terminationDialogOpen, setTerminationDialogOpen] = useState(false);
  const [rehireDialogOpen, setRehireDialogOpen] = useState(false);

  const handleOpenTerminationDialog = useCallback(() => {
    setTerminationDialogOpen(true);
  }, []);

  const confirmationDialogProps = {
    open: rehireDialogOpen || terminationDialogOpen,
    title: rehireDialogOpen ? 'Confirm Rehire' : 'Confirm Termination',
    color: !rehireDialogOpen ? 'error' : undefined,
    subtitle: (
      <>
        Are you sure you want to {rehireDialogOpen ? 'rehire' : 'terminate'}{' '}
        <b>
          {member?.firstName} {member?.lastName}
        </b>
        ?
      </>
    ),
    onClose: () => {
      (rehireDialogOpen ? setRehireDialogOpen : setTerminationDialogOpen)(
        false,
      );
    },
    onConfirm: rehireDialogOpen
      ? handleRehireTeamMember
      : handleTerminateTeamMember,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
  };

  useEffect(() => {
    dispatch(
      setPageTitle(
        member
          ? `Edit ${member.firstName} ${member.lastName}`
          : 'Add Team Member',
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack p={3} gap={3}>
      <Stack direction="row" justifyContent="space-between" mb={1}>
        <Button
          variant="text"
          startIcon={<ChevronLeft />}
          onClick={() => navigate('/dashboard/team')}
          size="small"
        >
          Back
        </Button>

        {member && !isTerminated && (
          <LoadingButton
            variant="text"
            color="error"
            isLoading={isTerminatingTeamMember}
            startIcon={<GroupRemoveRoundedIcon />}
            onClick={handleOpenTerminationDialog}
          >
            Terminate Team Member
          </LoadingButton>
        )}
      </Stack>

      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {() => (
          <TeamMemberDetailForm
            isEditing={isEditing}
            isTerminated={isTerminated}
            initialValues={initialValues}
            onSetRehireDialogOpen={setRehireDialogOpen}
            isRehiringTeamMember={isRehiringTeamMember}
            lastLoggedInAt={member?.lastLoggedInAt}
          />
        )}
      </Formik>

      <ConfirmationDialog {...confirmationDialogProps} />
    </Stack>
  );
};

export default TeamMemberDetail;
