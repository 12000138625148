import React, { useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';

interface CopyToClipboardProps {
  value: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ value }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(value);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'} arrow>
      <Button
        onClick={handleCopy}
        variant="text"
        color="secondary"
        size="small"
        startIcon={<FileCopyIcon />}
      >
        Copy
      </Button>
    </Tooltip>
  );
};

export default CopyToClipboard;
