import { Box, Stack, Tooltip, Typography } from '@mui/material';
import Info from '@mui/icons-material/InfoOutlined';
import React from 'react';

const SectionTitle = ({
  title,
  tooltip,
}: {
  title: string;
  tooltip?: string;
}) => {
  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="body1" color="grey.800" mr={1}>
        {title}
      </Typography>

      {tooltip && (
        <Box
          color="grey.400"
          sx={{ cursor: 'pointer' }}
          height="24px"
          width="24px"
        >
          <Tooltip title={tooltip}>
            <Info />
          </Tooltip>
        </Box>
      )}
    </Stack>
  );
};

export default SectionTitle;
