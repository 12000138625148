import React from 'react';

import FileUploadField from '../FileUploadField';

const IdentificationStep = ({ isReadonly }: { isReadonly: boolean }) => {
  return (
    <>
      <FileUploadField
        readOnly={isReadonly}
        name="idUpload"
        uwConfig={{
          folder: 'identification',
          tags: ['apps', 'verification', 'personal_id'],
        }}
        label="Upload Identification (Only government issued IDs)"
      />
    </>
  );
};

export default IdentificationStep;
