import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';

import PayLinkForm from '@page/PayLink/PayLinkForm';
import { createPaymentLink } from '@action/app.action';
import { useAppDispatch } from '@hook/hooks.hook';
import PayLinkPreview from '@page/PayLink/PayLinkPreview';
import PayLinkSent from '@page/PayLink/PayLinkSent';
import LoadingButton from '@component/LoadingButton';

const validationSchema = Yup.object({
  appId: Yup.string().required('App is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  amount: Yup.number()
    .required('Amount is required')
    .positive('Amount must be greater than zero'),
  extraDetails: Yup.array().of(
    Yup.object({
      key: Yup.string()
        .required('Key is required')
        .test(
          'not-id',
          'This key is not allowed. Please choose a different one.',
          (value) => !['id'].includes(value),
        ),
      value: Yup.string().required('Value is required'),
    }),
  ),
});

enum VIEWS {
  CREATE = 'create',
  PREVIEW = 'preview',
  DONE = 'done',
}

const PayLinkFormDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [view, setView] = useState(VIEWS.CREATE);
  const dispatch = useAppDispatch();
  const buttonText = view === VIEWS.CREATE ? 'Preview' : 'Send';

  const handleClose = () => {
    onClose();
    setView(VIEWS.CREATE);
  };

  const goBack = () => {
    setView(VIEWS.CREATE);
  };

  const handleSubmit = useCallback(
    async ({ extraDetails: submitExtraDetails, ...values }: any) => {
      if (view === VIEWS.CREATE) {
        setView(VIEWS.PREVIEW);
        return Promise.resolve();
      }

      const extras = submitExtraDetails.reduce(
        (memo: any, { key, value }: any) => {
          memo[key] = value;
          return memo;
        },
        {},
      );

      const res: any = await dispatch(
        createPaymentLink({ ...values, ...extras }),
      );

      if (!res.error) {
        setView(VIEWS.DONE);
      }
    },
    [dispatch, view],
  );

  let title =
    view === VIEWS.CREATE ? 'Create a payment link' : 'Preview payment link';
  if (view === VIEWS.DONE) title = 'PayLink Sent';

  return open ? (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle mt={4} mb={2} fontFamily="Lato" fontWeight={600}>
        {title}
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        size="small"
        sx={{
          position: 'absolute',
          right: 24,
          top: 42,
          color: 'grey.600',
        }}
      >
        <CloseIcon />
      </IconButton>

      <Divider variant="fullWidth" sx={{ width: '92%', m: 'auto' }} />

      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={{
          appId: '',
          email: '',
          amount: '',
          extraDetails: [] as any[],
        }}
        validationSchema={validationSchema}
      >
        {({ values, isValid, isSubmitting }) => (
          <Form>
            <DialogContent>
              {view === VIEWS.CREATE && <PayLinkForm />}

              {view === VIEWS.PREVIEW && <PayLinkPreview {...values} />}

              {view === VIEWS.DONE && <PayLinkSent />}
            </DialogContent>

            <DialogActions>
              <Stack
                justifyContent="space-between"
                direction="row"
                width="100%"
                alignItems="center"
                p={2}
                pb={3}
              >
                {view !== VIEWS.DONE && (
                  <>
                    <Stack direction="row" gap="4px">
                      <Box height={4} width={23} bgcolor="secondary.600" />
                      <Box
                        height={4}
                        width={23}
                        bgcolor={
                          view === VIEWS.PREVIEW ? 'secondary.600' : '#E8E8E8'
                        }
                      />
                    </Stack>
                    <Stack spacing={2} direction="row">
                      {/* Submit Button */}
                      {view === VIEWS.PREVIEW && (
                        <Button
                          type="submit"
                          variant="outlined"
                          color="primary"
                          onClick={goBack}
                          size="large"
                        >
                          Back
                        </Button>
                      )}

                      {Boolean(values.appId) && (
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          disabled={!isValid || isSubmitting}
                          isLoading={isSubmitting}
                        >
                          {buttonText}
                        </LoadingButton>
                      )}
                    </Stack>
                  </>
                )}
              </Stack>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  ) : null;
};

export default PayLinkFormDialog;
