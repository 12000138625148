import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { AdvancedImage } from '@cloudinary/react';
import dayjs from 'dayjs';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';

import cld from '@localCloudinary';
import { CompanyDirector } from '@interface/company.interface';
import { COUNTRY_NAME, getRegionName } from '@variable';

const ImageUploadDisplay: React.FC<{ label: string; imageUrl?: string }> = ({
  label,
  imageUrl,
}) => {
  const img = cld
    .image(imageUrl)
    .format('auto')
    .quality('auto')
    .resize(auto().gravity(autoGravity()));

  return (
    <Grid item xs={12} sm={6}>
      <Box>
        <Typography variant="caption">{label}</Typography>
      </Box>
      <AdvancedImage
        cldImg={img}
        style={{
          maxWidth: 400,
          height: 'auto',
          width: '100%',
        }}
      />
    </Grid>
  );
};

const DetailItem: React.FC<{
  label: string;
  value: string | React.ReactNode;
}> = ({ label, value }) => (
  <Grid item xs={12} sm={6}>
    <Typography variant="caption">{label}</Typography>
    <Typography variant="body1">{value}</Typography>
  </Grid>
);

const CompanyDirectorDetailView: React.FC<CompanyDirector> = ({
  fullName,
  dateOfBirth,
  nationality,
  idUpload,
  email,
  phoneNumber,
  street,
  countryOfResidence,
  city,
  state,
  utilityBillUpload,
}) => {
  const SECTION_ONE = [
    {
      label: 'Date of Birth',
      value: dayjs(dateOfBirth).format('MMMM D, YYYY'),
    },
    { label: 'Email', value: email },
    { label: 'Phone Number', value: `+234 ${phoneNumber}` },
    nationality && {
      label: 'Nationality',
      value: COUNTRY_NAME[nationality],
    },
  ].filter(Boolean);

  const SECTION_TWO = [
    countryOfResidence && {
      label: 'Country of Residence',
      value: COUNTRY_NAME[countryOfResidence],
    },
    countryOfResidence &&
      state && {
        label: 'State of Residence',
        value: getRegionName(countryOfResidence, state),
      },
    { label: 'City of Residence', value: city },
    { label: 'Street', value: street },
  ].filter(Boolean);

  return (
    <Box p={2} maxWidth={1000}>
      <Typography variant="h5" gutterBottom>
        {fullName}
      </Typography>

      <Grid container spacing={5}>
        <Grid container item xs={12} spacing={2}>
          {SECTION_ONE.map((detail: any, index) => (
            <DetailItem key={index} label={detail.label} value={detail.value} />
          ))}
        </Grid>

        <Grid container item xs={12} spacing={2}>
          {SECTION_TWO.map((detail: any, index) => (
            <DetailItem key={index} label={detail.label} value={detail.value} />
          ))}
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid xs={6} item>
            <ImageUploadDisplay label="ID Upload" imageUrl={idUpload} />
          </Grid>
          <Grid xs={6} item>
            <ImageUploadDisplay
              label="Utility Bill Upload"
              imageUrl={utilityBillUpload}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyDirectorDetailView;
