import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Field } from 'formik';

import { ALLOWED_NATIONALITIES_MAP } from '@variable';

const CountrySelectField = ({ label, sx, ...fieldProps }: any) => {
  return (
    <FormControl variant="outlined" sx={sx}>
      <InputLabel>{label || 'Country'}</InputLabel>
      <Field as={Select} label="Country" {...fieldProps}>
        {ALLOWED_NATIONALITIES_MAP.map(({ value, title }) => (
          <MenuItem key={value} value={value}>
            {title}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
};

export default CountrySelectField;
