import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { red } from '@mui/material/colors';
import dayjs from 'dayjs';

import User from '@interface/user.interface';
import AppLoader from '@component/AppLoader';
import { ROLE_MAP } from '@variable';
import UserStatus from '@enum/userStatus.enum';
import { setPageTitle } from '@action/company.action';
import { useAppDispatch } from '@hook/hooks.hook';
import NoActivities from '@component/NoActivities';

interface TeamMemberTableProps {
  teamMembers: User[];
  isLoadingCompanyTeamMembers: boolean;
}

const TeamMemberTable: FC<TeamMemberTableProps> = ({
  teamMembers,
  isLoadingCompanyTeamMembers,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageTitle('Team'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showTerminated, setShowTerminated] = useState(false);
  const filteredTeamMembers = showTerminated
    ? teamMembers
    : teamMembers.filter(
        (teamMember) => !(teamMember.status === UserStatus.TERMINATED),
      );

  const hasTeamMembers = Boolean(filteredTeamMembers?.length);

  return (
    <Box px={3}>
      {hasTeamMembers ? (
        <>
          <Toolbar sx={{ justifyContent: 'space-between', py: 3 }}>
            <Typography variant="body1" fontSize="20px" fontWeight="bold">
              List Of Members ({filteredTeamMembers.length})
            </Typography>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControlLabel
                control={<Checkbox checked={showTerminated} />}
                label="Show terminated"
                onClick={() => {
                  setShowTerminated((value) => !value);
                }}
              />

              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => {
                  navigate('new');
                }}
              >
                Add Team Member
              </Button>
            </Box>
          </Toolbar>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: 'rgba(223, 239, 249, 0.20)',
                  }}
                >
                  <TableCell>Full Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Last Login</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredTeamMembers.map((member) => {
                  const isTerminated = member.status === UserStatus.TERMINATED;

                  return (
                    <TableRow
                      key={member.email}
                      sx={{
                        backgroundColor: isTerminated ? red['100'] : 'initial',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        navigate(String(member.id));
                      }}
                    >
                      <TableCell>
                        {member.firstName} {member.lastName}
                      </TableCell>
                      <TableCell>{member.email}</TableCell>
                      <TableCell>{ROLE_MAP[member.role]}</TableCell>
                      <TableCell>
                        {member.lastLoggedInAt
                          ? dayjs(member.lastLoggedInAt).format(
                              'hh:mm A MMMM D, YYYY',
                            )
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {isLoadingCompanyTeamMembers && (
              <Box my={4}>
                <AppLoader size={8} />
              </Box>
            )}
          </TableContainer>
        </>
      ) : (
        <NoActivities
          height="80vh"
          title="No team members"
          subTitle={
            <>
              You don&apos;t have any team members yet.
              <br />
              Once you add them, they will appear here.
            </>
          }
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              navigate('new');
            }}
          >
            Add Team Member
          </Button>
        </NoActivities>
      )}
    </Box>
  );
};

export default TeamMemberTable;
