import React, { useCallback, useEffect } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';

import loginBack from '@asset/images/loginBack.webp';
import click from '@asset/icons/click.webp';
import useTawkTo from '@hook/useTawkTo.hook';
import LoadingButton from '@component/LoadingButton';

const LoginForm: React.FC = () => {
  const { loginWithRedirect, isLoading } = useAuth0();
  const [searchParams] = useSearchParams();
  const action = searchParams.get('action');
  const { maximize: maximizeTawkTo }: any = useTawkTo();

  const handleLogin = useCallback(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  useEffect(() => {
    if (action !== 'logout') handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        position: 'relative',
        background: `url(${loginBack}) no-repeat center center`,
        backgroundSize: 'cover',
        justifyContent: 'center',
      }}
    >
      <Stack
        gap={2}
        textAlign="center"
        alignItems="center"
        justifyContent="center"
      >
        <img
          width={190}
          height="auto"
          alt="zinaripay logo"
          src={'/logoDefault.webp'}
          style={{ position: 'absolute', top: 32, left: 163 }}
        />
        <Box position="relative">
          <Typography variant="h2" fontWeight={700} fontSize={72}>
            You’re just one click
            <br />
            away
          </Typography>
          <img
            width={50}
            height="auto"
            alt="zinaripay click"
            src={click}
            style={{
              top: 14,
              right: -60,
              position: 'absolute',
            }}
          />
        </Box>
        <Stack gap={2} flexDirection="row">
          {action === 'logout' && (
            <LoadingButton
              color={'primary'}
              variant="contained"
              onClick={handleLogin}
              isLoading={isLoading}
              sx={{ textTransform: 'none' }}
            >
              Enter Console
            </LoadingButton>
          )}

          <Button
            color={'primary'}
            onClick={maximizeTawkTo}
            variant="outlined"
            sx={{ textTransform: 'none' }}
          >
            Contact Support
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default LoginForm;
