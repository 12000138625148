import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import AppLoader from '@component/AppLoader';
import { useAuth } from '@hook/useAuth.hook';
import BasicInfoSection from '@page/Settings/BasicInfoSection';
import BusinessOwnerForm from '@page/Settings/BusinessOwnerForm';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { selectBusinessOwnerKyc } from '@selector/auth.selector';
import BusinessOwnerKyc from '@interface/businessOwnerKyc.interface';
import {
  selectCanEditCompanyDetails,
  selectIsBusinessOwner,
} from '@selector/permission.selector';
import CompanyDirectorDetailView from '@component/CompanyDirectorDetailView';
import Company, { CompanyDirector } from '@interface/company.interface';
import { setPageTitle, updateCompany } from '@action/company.action';
import CompanyManagementPage from '@page/Company';
import {
  selectCompanyInfo,
  selectIsLoadingCompany,
} from '@selector/company.selector';

const TABS = ['Basic Info', 'Company KYC', 'Business Owner KYC'];

const TabPanel: React.FC<any> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const Settings = () => {
  const { isAuthenticating } = useAuth();
  const businessOwnerKyc = useAppSelector(selectBusinessOwnerKyc);
  const isBusinessOwner = useAppSelector(selectIsBusinessOwner);
  const isVerified = businessOwnerKyc?.isVerified;
  const verificationRequested = businessOwnerKyc?.verificationRequested;
  const showForm = isBusinessOwner && !isVerified && !verificationRequested;

  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsTab = Number(searchParams.get('tab') || 0);
  const [value, setValue] = useState<number>(searchParamsTab);
  const isLoadingCompany = useAppSelector(selectIsLoadingCompany);
  const company = useAppSelector(selectCompanyInfo);

  useEffect(() => {
    setSearchParams({ tab: String(value) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    dispatch(setPageTitle('Settings'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const updateCompanyRegistrationStatus = useCallback(
    (_: any, isRegistered: boolean) => {
      dispatch(updateCompany({ isRegistered } as Company));
    },
    [dispatch],
  );

  const canEditCompanyDetails = useAppSelector(selectCanEditCompanyDetails);
  useState(false);

  const businessOwnerKycIsVerified =
    !company?.isRegistered && businessOwnerKyc.isVerified;

  const showIsRegisteredToggle =
    !businessOwnerKycIsVerified &&
    !company.isVerified &&
    !company.verificationRequested &&
    canEditCompanyDetails;

  return isAuthenticating ? (
    <AppLoader />
  ) : (
    <Stack px={2} py={3}>
      <Box
        sx={{
          position: 'relative',
          borderBottom: '1px solid',
          borderColor: 'primary.100',
        }}
      >
        {showIsRegisteredToggle && (
          <Box position="absolute" top={0} right={0} zIndex={1}>
            <FormControlLabel
              control={
                <Switch
                  disabled={isLoadingCompany}
                  checked={company?.isRegistered}
                  onChange={updateCompanyRegistrationStatus}
                  color="secondary"
                />
              }
              label={
                <Typography variant="body2" color="grey.800">
                  {company?.isRegistered
                    ? 'Registered Company'
                    : 'Unregistered Company'}
                </Typography>
              }
            />
          </Box>
        )}

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="settings tabs"
          scrollButtons="auto"
          visibleScrollbar
          TabIndicatorProps={{
            sx: {
              height: '3px',
              bgcolor: 'secondary.600',
              borderRadius: '4px 4px 0px 0px',
            },
          }}
        >
          {(isBusinessOwner ? TABS : [TABS[0]]).map((tab) => (
            <Tab label={tab} key={tab} sx={{ textTransform: 'capitalize' }} />
          ))}
        </Tabs>
      </Box>

      <Stack gap={4}>
        <TabPanel index={0} value={value}>
          <Box p={2}>
            <BasicInfoSection />
            <Divider
              variant="fullWidth"
              sx={{ width: 'calc(100% + 32px)', ml: '-16px', my: 5 }}
            />
          </Box>
        </TabPanel>

        <TabPanel index={1} value={value}>
          <CompanyManagementPage />
        </TabPanel>

        <TabPanel index={2} value={value}>
          <Stack gap={2} p={2}>
            {showForm && (
              <BusinessOwnerForm
                {...(businessOwnerKyc || ({} as BusinessOwnerKyc))}
              />
            )}

            {!showForm && isBusinessOwner && (
              <>
                <Typography variant="body1" fontSize="20px" fontWeight="bold">
                  Business Owner KYC
                </Typography>

                {!isVerified && verificationRequested && (
                  <Alert severity="info">
                    <AlertTitle sx={{ fontWeight: 'bold' }}>
                      Verification Pending
                    </AlertTitle>
                    Our team has received your verification request. You will be
                    notified once your account is verified.
                  </Alert>
                )}

                {isVerified && (
                  <Alert severity="success">
                    <AlertTitle sx={{ fontWeight: 'bold' }}>
                      Verified
                    </AlertTitle>
                    Our team has successfully verified your information. To
                    update this information, you&apos;ll need to contact
                    support.
                  </Alert>
                )}

                {isBusinessOwner && (
                  <CompanyDirectorDetailView
                    {...((businessOwnerKyc as unknown as CompanyDirector) ||
                      ({} as CompanyDirector))}
                    fullName={`${businessOwnerKyc?.firstName} ${businessOwnerKyc?.lastName}`}
                  />
                )}
              </>
            )}
          </Stack>
        </TabPanel>
      </Stack>
    </Stack>
  );
};

export default Settings;
