import { Field } from 'formik';
import { InputAdornment } from '@mui/material';
import React from 'react';
import { getCountryCallingCode } from 'libphonenumber-js';

const PhoneNumberField = ({ country, ...props }: any) => {
  let phoneCode;

  try {
    phoneCode = '+' + getCountryCallingCode(country as any);
  } catch (err) {
    console.log(err);
  }

  return (
    <Field
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{phoneCode}</InputAdornment>
        ),
      }}
    />
  );
};

export default PhoneNumberField;
