import { Stack, TextField, Typography } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';

import { HTTPS_WEBHOOK_URL } from '@variable';
import { updateApp } from '@action/app.action';
import { useAppDispatch } from '@hook/hooks.hook';
import App from '@interface/app.interface';
import SectionTitle from '@component/AppDetail/SectionDetail';
import LoadingButton from '@component/LoadingButton';

interface WebhookSectionProps {
  id: string;
  transactionDetectedWebhookUrl?: string;
  transactionDoneWebhookUrl?: string;
  incompletePaymentCompletionWebhookUrl?: string;
}

const WebhookSection: FC<WebhookSectionProps> = ({
  id,
  transactionDetectedWebhookUrl: defaultTransactionDetectedWebhookUrl,
  transactionDoneWebhookUrl: defaultTransactionDoneWebhookUrl,
  incompletePaymentCompletionWebhookUrl:
    defaultIncompletePaymentCompletionWebhookUrl,
}) => {
  const dispatch = useAppDispatch();
  const [transactionDoneWebhookUrl, setTransactionDoneWebhookUrl] = useState(
    defaultTransactionDoneWebhookUrl || '',
  );
  const [isSaving, setIsSaving] = useState(false);
  const [transactionDetectedWebhookUrl, setTransactionDetectedWebhookUrl] =
    useState(defaultTransactionDetectedWebhookUrl || '');
  const [
    incompletePaymentCompletionWebhookUrl,
    setIncompletePaymentCompletionWebhookUrl,
  ] = useState(defaultIncompletePaymentCompletionWebhookUrl || '');

  const handleWebhookUrlChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newUrl = event.target.value;
    setTransactionDoneWebhookUrl(newUrl);
  };

  const isUrlValid = useCallback((url: string, defaultValue?: string) => {
    return (
      !url ||
      url === defaultValue ||
      (url !== defaultValue && HTTPS_WEBHOOK_URL.test(url))
    );
  }, []);

  const allValuesSame =
    defaultTransactionDoneWebhookUrl === transactionDoneWebhookUrl &&
    defaultTransactionDetectedWebhookUrl === transactionDetectedWebhookUrl &&
    defaultIncompletePaymentCompletionWebhookUrl ===
      incompletePaymentCompletionWebhookUrl;

  const urlInvalid =
    !isUrlValid(transactionDoneWebhookUrl, defaultTransactionDoneWebhookUrl) ||
    !isUrlValid(
      transactionDetectedWebhookUrl,
      defaultTransactionDetectedWebhookUrl,
    ) ||
    !isUrlValid(
      incompletePaymentCompletionWebhookUrl,
      defaultIncompletePaymentCompletionWebhookUrl,
    );

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!urlInvalid) {
        setIsSaving(true);
        await dispatch(
          updateApp({
            id,
            transactionDoneWebhookUrl,
            transactionDetectedWebhookUrl,
            incompletePaymentCompletionWebhookUrl,
          } as App),
        );
        setIsSaving(false);
      }
    },
    [
      id,
      dispatch,
      urlInvalid,
      transactionDoneWebhookUrl,
      transactionDetectedWebhookUrl,
      incompletePaymentCompletionWebhookUrl,
    ],
  );

  return (
    <Stack spacing={3}>
      <Stack gap="12px">
        <Typography variant="body1" fontSize="20px" fontWeight="bold">
          Configure Webhook URLs
        </Typography>
        <Typography variant="body1" color="grey.700">
          We will call these POST endpoints with real-time notifications about
          your app
        </Typography>
      </Stack>

      <form onSubmit={handleSubmit}>
        <Stack
          mt={1}
          gap={2}
          direction="row"
          flexWrap="wrap"
          flexDirection="row"
          alignItems="baseline"
          justifyContent="flex-start"
        >
          <Stack gap={2} sx={{ width: '356px' }}>
            <SectionTitle
              title="Transaction Detected URL"
              tooltip="This webhook is called when incoming transactions are detected to your app"
            />

            <TextField
              type="url"
              fullWidth
              value={transactionDetectedWebhookUrl}
              label="Enter URL"
              onChange={(e) => {
                setTransactionDetectedWebhookUrl(e.target.value);
              }}
              placeholder='e.g., "https://api.example.com/webhook"'
              error={
                !isUrlValid(
                  transactionDetectedWebhookUrl,
                  defaultTransactionDetectedWebhookUrl,
                )
              }
              helperText={
                !isUrlValid(
                  transactionDetectedWebhookUrl,
                  defaultTransactionDetectedWebhookUrl,
                )
                  ? 'Please enter a valid HTTPS URL'
                  : ''
              }
            />
          </Stack>

          <Stack gap={2} sx={{ width: '356px' }}>
            <SectionTitle
              title="Transaction Done Webhook URL"
              tooltip="This webhook is called when transactions are 'confirmed', 'failed', 'excess' or 'incomplete'"
            />
            <TextField
              type="url"
              fullWidth
              value={transactionDoneWebhookUrl}
              label="Enter URL"
              onChange={handleWebhookUrlChange}
              placeholder='e.g., "https://api.example.com/webhook"'
              error={
                !isUrlValid(
                  transactionDoneWebhookUrl,
                  defaultTransactionDoneWebhookUrl,
                )
              }
              helperText={
                !isUrlValid(
                  transactionDoneWebhookUrl,
                  defaultTransactionDoneWebhookUrl,
                )
                  ? 'Please enter a valid HTTPS URL'
                  : ''
              }
            />
          </Stack>

          <Stack gap={2} sx={{ width: '356px' }}>
            <SectionTitle
              title="Incomplete Payment Completion URL"
              tooltip="This webhook is called when a previously 'incomplete' payment is completed"
            />

            <TextField
              type="url"
              fullWidth
              value={incompletePaymentCompletionWebhookUrl}
              label="Enter URL"
              onChange={(e) => {
                setIncompletePaymentCompletionWebhookUrl(e.target.value);
              }}
              placeholder='e.g., "https://api.example.com/webhook"'
              error={
                !isUrlValid(
                  incompletePaymentCompletionWebhookUrl,
                  defaultIncompletePaymentCompletionWebhookUrl,
                )
              }
              helperText={
                !isUrlValid(
                  incompletePaymentCompletionWebhookUrl,
                  defaultIncompletePaymentCompletionWebhookUrl,
                )
                  ? 'Please enter a valid HTTPS URL'
                  : ''
              }
            />
          </Stack>
        </Stack>

        <Stack alignItems="flex-end" mt={4}>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={allValuesSame || urlInvalid}
            size="large"
            isLoading={isSaving}
          >
            Save Webhook URL
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
};

export default WebhookSection;
