import { createSelector } from '@reduxjs/toolkit';

import { SLICE_NAME } from '@variable';
import { RootState } from '@store';
import Company from '@interface/company.interface';
import { AppTransactionAttributes } from '@interface/appTransaction.interface';
import { selectBusinessOwnerKyc } from '@selector/auth.selector';
import { PayLink } from '@interface/initialState.interface';

export const selectCompany = (state: RootState) => state[SLICE_NAME].company;

export const selectCompanyTransactions = (state: RootState) =>
  state[SLICE_NAME].companyAppTransactions;

export const selectPayLinks = (state: RootState) => state[SLICE_NAME].payLinks;

export const selectPayLinkData = createSelector(
  selectPayLinks,
  (payLinks) => payLinks?.data,
);

export const selectPayLinkById = createSelector(
  (_, id) => id,
  selectPayLinkData,
  (id, paylinks: PayLink[]) => paylinks?.find((paylink) => paylink.id === id),
);

export const selectCompanyAppTransactions = createSelector(
  selectCompanyTransactions,
  (companyAppTransactions) => companyAppTransactions?.data,
);

export const selectIsFetchingCompanyAppTransactions = (state: RootState) =>
  state[SLICE_NAME].isFetchingCompanyAppTransactions;

export const selectIsFetchingPayLinks = (state: RootState) =>
  state[SLICE_NAME].isFetchingPayLinks;

export const selectPageTitle = (state: RootState) =>
  state[SLICE_NAME].pageTitle;

export const selectCompanyWalletBalances = createSelector(
  selectCompany,
  (company) => company?.appWallets,
);

export const selectCompanyAppTransactionById = createSelector(
  (_, id) => id,
  selectCompanyAppTransactions,
  (id: string, companyAppTransactions?: AppTransactionAttributes[]) =>
    companyAppTransactions?.find((transaction) => transaction.id === id),
);

export const selectCompanyInfo = createSelector(
  selectCompany,
  (company?: Company) => {
    return {
      name: company?.name,
      type: company?.type,
      taxRate: company?.taxRate,
      feeRate: company?.feeRate,
      isVerified: company?.isVerified,
      isRegistered: company?.isRegistered,
      supportEmail: company?.supportEmail,
      cacDocUpload: company?.cacDocUpload,
      supportPhoneNumber: company?.supportPhoneNumber,
      verificationRequested: company?.verificationRequested,
      countryOfIncorporation: company?.countryOfIncorporation,
    } as Company;
  },
);

export const selectCompanyDirectors = createSelector(
  selectCompany,
  (company?: Company) => company?.directors,
);

export const selectVerifiedCompanyDirectors = createSelector(
  selectCompanyDirectors,
  (directors) => directors?.filter((director) => director.isVerified),
);

export const selectUnverifiedCompanyDirectors = createSelector(
  selectCompanyDirectors,
  (directors) =>
    directors?.filter(
      (director) => !director.isVerified && !director.verificationRequested,
    ),
);

export const selectPendingVerificationCompanyDirectors = createSelector(
  selectCompanyDirectors,
  (directors) =>
    directors?.filter(
      (director) => director.verificationRequested && !director.isVerified,
    ),
);

export const selectTeamMembers = (state: RootState) =>
  state[SLICE_NAME].teamMembers;

export const selectIsLoadingCompany = (state: RootState) =>
  state[SLICE_NAME].isLoadingCompany;

export const selectTeamMemberById = createSelector(
  [selectTeamMembers, (_, props) => props],
  (teamMembers, { id }) => {
    return teamMembers.find((user) => user.id === id);
  },
);

export const selectIsLoadingCompanyTeamMembers = (state: RootState) =>
  state[SLICE_NAME].isLoadingCompanyTeamMembers;

export const selectIsUpdatingCompany = (state: RootState) =>
  state[SLICE_NAME].isUpdatingCompany;

export const selectKycIsCompletelyVerified = createSelector(
  selectCompanyInfo,
  selectCompanyDirectors,
  selectBusinessOwnerKyc,
  (company, companyDirectors, businessOwnerKyc) =>
    (!company?.isRegistered && businessOwnerKyc?.isVerified) ||
    (company.isVerified &&
      businessOwnerKyc?.isVerified &&
      Boolean(
        companyDirectors?.length &&
          companyDirectors.some((director) => director.isVerified),
      )),
);
