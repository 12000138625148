import { useEffect } from 'react';

import TeamMemberTable from '@component/TeamMemberTable';
import { getCompanyTeamMembers } from '@action/company.action';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import {
  selectIsLoadingCompanyTeamMembers,
  selectTeamMembers,
} from '@selector/company.selector';
import AppLoader from '@component/AppLoader';

const TeamMembers = () => {
  const dispatch = useAppDispatch();
  const teamMembers = useAppSelector(selectTeamMembers);
  const isLoadingCompanyTeamMembers = useAppSelector(
    selectIsLoadingCompanyTeamMembers,
  );

  useEffect(() => {
    dispatch(getCompanyTeamMembers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoadingCompanyTeamMembers && !teamMembers.length ? (
    <AppLoader />
  ) : (
    <TeamMemberTable
      teamMembers={teamMembers}
      isLoadingCompanyTeamMembers={isLoadingCompanyTeamMembers}
    />
  );
};

export default TeamMembers;
