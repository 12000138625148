import { Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';

import SectionTitle from '@component/AppDetail/SectionDetail';
import { updateApp } from '@action/app.action';
import { useAppDispatch } from '@hook/hooks.hook';
import App from '@interface/app.interface';
import LoadingButton from '@component/LoadingButton';

const CustomAuthorizationSection = ({
  id,
  customAuthorizationKey,
}: {
  id: string;
  customAuthorizationKey?: string;
}) => {
  const dispatch = useAppDispatch();
  const [customKey, setCustomKey] = useState(customAuthorizationKey || '');
  const [isSaving, setIsSaving] = useState(false);
  const valueSame = customAuthorizationKey === customKey;

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!valueSame) {
        setIsSaving(true);
        await dispatch(
          updateApp({ id, customAuthorizationKey: customKey } as App),
        );
        setIsSaving(false);
      }
    },
    [customKey, dispatch, id, valueSame],
  );

  return (
    <Stack spacing={3}>
      <Stack gap="12px">
        <Typography variant="body1" fontSize="20px" fontWeight="bold">
          Custom Authorization Key
        </Typography>
        <Typography variant="body1" color="grey.700">
          This will be sent as the Bearer token in the Authorization header with
          every webhook request. We recommend this to increase your accounts
          security.
        </Typography>
      </Stack>

      <form onSubmit={handleSubmit}>
        <Stack gap={2} sx={{ width: '356px' }}>
          <SectionTitle
            title="Key"
            tooltip="This can be a JWT token or any secure private string used for authorization purposes."
          />
          <TextField
            name="customAuthorizationKey"
            label="Custom Key"
            placeholder="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55SEDiOiI3YTc5NDc5MS1lKDCjLTQ2NDMtYWE1ZS0wOTJkNTQyZDBmOWQiLCJhcHBJZCI6IlP3NzUzYzc3LTQ2PzktNDU5MS04RjE2LWNmNGZiYmYyMjllOCIsImlhdCI6MTcyODg3MDc5Mn0.Lmnopq"
            value={customKey}
            onChange={(e) => {
              setCustomKey(e.target.value);
            }}
          />
        </Stack>

        <Stack alignItems="flex-end" mt={4}>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={valueSame}
            size="large"
            isLoading={isSaving}
          >
            Save Key
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
};

export default CustomAuthorizationSection;
